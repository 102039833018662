import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/companies/company.service';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { DataTransformationsService } from 'src/app/services/utilities/data/data-transformations.service';
import { SetupInsertEmailComponent } from '../setup-insert-email/setup-insert-email.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { AddBillingAccountComponent } from '../add-billing-account/add-billing-account.component';
import { BillingAccountsService } from 'src/app/services/billing-accounts/billing-accounts.service';
import { GenerateMonthlyBillingComponent } from '../generate-monthly-billing/generate-monthly-billing.component';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-view-company',
  templateUrl: './view-company.component.html',
  styleUrls: ['./view-company.component.css'],
})

export class ViewCompanyComponent {

  ccDoAll: any = false;
  fetch_data: any = [];
  CustomerAssigned = '';
  response_data: any;
  setup_selected_value: any;
  selected_email_address: any;
  account_billing: any;
  constructor(
    public dialogRef: MatDialogRef<ViewCompanyComponent>,
    public dialog: MatDialog,
    private _companyServ: CompanyService,
    private _snackBar: SnackbarsService,
    private _StorageServ: StorageService,
    private formBuilder: FormBuilder,
    private _dataTransServ: DataTransformationsService,
    private _billingServ: BillingAccountsService,
    private changeDetectorRefs: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getToTowns();
    this.getFromTowns();

  }
  seacrchbox: any = '';
  clone_fetch_data: [] = [];
  selectedDriverImportOption: any;
  @ViewChild(MatMenuTrigger)
  triggerBtn!: MatMenuTrigger;


  tab = '1';

  selectTab(value: any) {
    this.tab = value;
  }

  openAccountMenu() {
    this.triggerBtn.openMenu();
  }

  private selectedCustomers: any = [];
  distance: any = {
    fromTowns: [],
    toTowns: [],
    distances: []
  };

  viewCompanyForm = this.formBuilder.group({
    COMPANYID: [this.data[0].COMPANYID],
    COMPANYNAME: [this.data[0].COMPANYNAME],
    CURRENCYSYMBOL: ['', Validators.required],
    TRAILING: ['', Validators.required],
    FUELMEASUREID: ['', Validators.required],
    FROMTOWNNAME: ['', Validators.required],
    TOTOWNNAME: ['', Validators.required],
    DISTANCEID: ['', Validators.required],
    PERCLOWERLIMIT: ['', Validators.required],
    PERCUPPERLIMIT: ['', Validators.required],
    PERCNOLOSS: ['', Validators.required],
    TANKEXCEED: ['', Validators.required],
    DAYSTOEDIT: ['', Validators.required],
    HIGHODO: ['', Validators.required],
    TEL: [''],
    LPERHOURCALCULATION: [''],
    CONTACTPERSON: [''],
    INUSE: [''],
    ASSIGNDRIVERSATIMPORT: [''],
    USEAS400DRIVERS: [''],
    AVISWESBANKID: [''],
    CELL: [''],
    CUSTOMER: [[]],
    USERID: this._StorageServ.getUserId(),
    UserName: this._StorageServ.getUserName(),
  });

  dataSource_Cost_Center = [];
  dataSource_customers = [];
  dataSource_accounts = [];

  dataSource_Customer_Email_Addresses = [

  ];
  dataSource_Account_Email_Addresses = [

  ];
  dataSource_Cost_Centre_Email_Addresses = new MatTableDataSource()
  dataSource_Division_Email_Addresses = [

  ];
  dataSource_Email_Addresses = [];
  displayedColumns_Customers: string[] = [
    'CUSTOMER_ID',
    'AS400CUSTOMERID',
    'CUSTOMERNAME',
    'AS400COMPANYID',
  ];
  displayedColumns_Accounts: string[] = [
    'ACCOUNT_ID',
    'AS400ACCOUNTID',
    'ACCOUNTNAME',
    'COMPANYNAME'
  ];
  displayedColumns_Cost_Center: string[] = [
    'COSTCENTREID',
    'ACCOUNTNAME',
    'COSTCENTRENAME',
  ];
  displayedColumns_Customer_Email_Addresses: string[] = [
    'EMAIL_ADDRESS',
    'EMAIL_TYPE_DESCRIPTION',
  ];
  displayedColumns_Cost_Centre_Email_Addresses: string[] = [
    'EMAIL_ADDRESS',
    'EMAIL_TYPE_DESCRIPTION',
  ];
  displayedColumns_Division_Email_Addresses: string[] = [
    'EMAIL_ADDRESS',
    'EMAIL_TYPE_DESCRIPTION',
  ];
  displayedColumns_Email_Addresses: string[] = [
    'EMAIL_ADDRESS',
    'EMAIL_TYPE_DESCRIPTION',
  ];
  importDriverOptions: any[] = [
    { "name": "In Use", "value": 1, 'checked': true },
    { "name": "Assign Drivers at import", "value": 2, 'checked': false },
    { "name": "Use AS400 Drivers", "value": 3, 'checked': false }
  ];
  radioChange(event: any) {
    this.selectedDriverImportOption = event.value;
    this.formatImportOptions(this.viewCompanyForm.value, event.value)
  }
  onRadioChange(event: any) {
    console.log(event.value);
    // Get the selected value
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.getAllCustomers();
    this.getCompanyAccounts(this.data[0].COMPANYID)
    this.getCompanyCostCentres(this.data[0].COMPANYID)
    this.getAllDistances();

  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }
  closeModal(data?: any): void {
    this.dialogRef.close({ event: data });
  }
  AssignCustomer(customer: any) {
    if (this.selectedCustomers.includes(customer.CUSTOMERNAME)) {
      this.removeElementFromArray(customer.CUSTOMERNAME);
      this.CustomerAssigned = '';
    } else {
      this.CustomerAssigned = customer.CUSTOMERNAME;
      let {
        COMPANYNAME: _,
        CUSTOMERID: __,
        COMPANYID: ___,
        ...new_customer
      } = customer;
      this.selectedCustomers.push(new_customer);
    }
  }
  removeElementFromArray(element: number) {
    this.selectedCustomers.forEach((value: number, index: any) => {
      if (value == element) this.selectedCustomers.splice(index, 1);
    });
  }
  getAllCustomers() {
    this._companyServ.getAllCustomers().subscribe(
      (response: any) => {
        this.fetch_data = response.results;
        this.clone_fetch_data = this.fetch_data;
        this.formatImportOptions(this.data[0], null, true);
        this._companyServ.getCompanyCustomers(this.data[0].COMPANYID).subscribe((response: any) => {
          this.dataSource_customers = response.results
          let current: number = 0;
          for (let customer of this.fetch_data) {
            if (customer.COMPANYID == this.data[0].COMPANYID) {
              customer.checked = true;
            } else {
              customer.checked = false;
            }
            current++;
          }
          console.log(this.fetch_data);
        },
          (error) => {

          })
      },
      (error) => {
        this._snackBar.error(error.message);
      }
    );
  }
  getAllDistances() {
    this._companyServ.getAllDistances().subscribe(
      (response: any) => {
        this.distance.distances = response;
      },
      (error) => {
        this._snackBar.error(error.message);
      }
    );
  }
  onSubmit(): void {
    this.viewCompanyForm.controls['CUSTOMER'].setValue(this.selectedCustomers);
    // this.viewCompanyForm.controls['USERID'].setValue(this.);
    this._companyServ.insertCompany(this.viewCompanyForm.value).subscribe(
      (response: any) => {
        //response to be updated
        this.viewCompanyForm.reset();
        if (response.Status == '200') {
          this._snackBar.success(response.Status);
        } else {
          this._snackBar.error(
            'Something Went wrong, please check you data and resubmit again.. '
          );
          return;
        }
      },
      (error) => {
        this._snackBar.error(error.message);
      }
    );
  }
  onUpdate(): void {
    this.viewCompanyForm.controls['CUSTOMER'].setValue(this.selectedCustomers);
    this._companyServ.updateCompany(this.viewCompanyForm.value).subscribe(
      (response: any) => {
        //response to be updated
        if (response.rowsAffected > 0) {
          this.closeModal(true);
          this._snackBar.success("Company Updated!");

        } else {
          this._snackBar.error(
            'Something Went wrong, please check you data and resubmit again.. '
          );
          return;
        }
      },
      (error) => {
        this._snackBar.error(error.message);
      }
    );
  }
  formatImportOptions(obj_data: any, ui_option?: any, selected_options?: boolean) {

    if (selected_options) {
      console.log("selected Option db option : " + selected_options);
      if (obj_data.INUSE == '1' && obj_data.ASSIGNDRIVERSIMPORT == '0' && obj_data.UseAS400Drivers == '0' || obj_data.UseAS400Drivers == null) {
        this.selectedDriverImportOption = '1';
        this.importDriverOptions[0].checked = true;
        this.importDriverOptions[1].checked = false;
        this.importDriverOptions[2].checked = false;
        console.log("in use : " + this.importDriverOptions);
      } else if (obj_data.INUSE == '1' && obj_data.ASSIGNDRIVERSIMPORT == '1' && obj_data.UseAS400Drivers == '0' || obj_data.UseAS400Drivers == null) {
        this.selectedDriverImportOption = '2'
        this.importDriverOptions[0].checked = false;
        this.importDriverOptions[1].checked = true;
        this.importDriverOptions[2].checked = false;
        console.log("in at import : " + this.importDriverOptions);
      } else if (obj_data.INUSE == '1' && obj_data.ASSIGNDRIVERSIMPORT == '1' && obj_data.UseAS400Drivers == '1') {
        this.selectedDriverImportOption = ' 3'
        this.importDriverOptions[0].checked = false;
        this.importDriverOptions[1].checked = false;
        this.importDriverOptions[2].checked = true;
        console.log("as400 : ")
      } else {
        console.log('Nothing happened');
      }
    } else if (ui_option) {
      console.log("selected Option ui option: " + this.selectedDriverImportOption);
      if (this.selectedDriverImportOption == '1') {
        this.viewCompanyForm.controls['INUSE'].setValue('1');
        this.viewCompanyForm.controls['ASSIGNDRIVERSATIMPORT'].setValue('0');
        this.viewCompanyForm.controls['USEAS400DRIVERS'].setValue('0');
      } else if (this.selectedDriverImportOption == '2') {
        this.viewCompanyForm.controls['INUSE'].setValue('1');
        this.viewCompanyForm.controls['ASSIGNDRIVERSATIMPORT'].setValue('1');
        this.viewCompanyForm.controls['USEAS400DRIVERS'].setValue('0');
      } else if (this.selectedDriverImportOption == '3') {
        this.viewCompanyForm.controls['INUSE'].setValue('1');
        this.viewCompanyForm.controls['ASSIGNDRIVERSATIMPORT'].setValue('1');
        this.viewCompanyForm.controls['USEAS400DRIVERS'].setValue('1');
      } else {
        console.log("Failed Too Append Values");
      }
    }
  }
  private getFromTowns() {
    this._companyServ.getFromTowns().subscribe(
      (response: any) => {
        this.distance.fromTowns = response.results;
      },
      (error) => {
        this._snackBar.error(error.message);
      }
    );
  }
  private getToTowns() {
    this._companyServ.getToTowns().subscribe(
      (response: any) => {
        this.distance.toTowns = response.results;
      },
      (error: any) => {
        this._snackBar.error(error.message);
      }
    );
  }
  searchArray(): void {

    this._dataTransServ.objArrFilter(this.fetch_data, this.seacrchbox)
  }
  getCompanyAccounts(company_id: any) {
    this._companyServ.getCompanyAccounts(company_id).subscribe((response: any) => {
      this.dataSource_accounts = response.results
      console.log('company accounts');
      console.log(this.dataSource_accounts);
    },
      (error) => {
      })
  }
  getCompanyCostCentres(company_id: any) {
    this._companyServ.getCompanyCostCentres(company_id).subscribe((response: any) => {
      this.dataSource_Cost_Center = response.results
      console.log('company cost centre');
      console.log(this.dataSource_Cost_Center);
    },
      (error) => {
      })
  }
  getCustomerEmailAddresses(customer: any) {
    this.setSelectedValue(customer, 'customer');
    this._companyServ.getCustomerEmailAddresses(customer.CUSTOMERID).subscribe((response: any) => {
      this.dataSource_Customer_Email_Addresses = response;
      if (response.length == 0) {
        this._snackBar.success('Customer has no assigned email');
      }
    })
  }
  getAccountEmailAddresses(account: any) {
    this.setSelectedValue(account, 'account');
    this._companyServ.getAccountEmailAddresses(account.ACCOUNTID).subscribe((response) => {
      this.dataSource_Account_Email_Addresses = response;
      if (response.length == 0) {
        this._snackBar.success('Account has no assigned email');
      }
    })
  }
  getCostCentreEmailAddresses(cost_centre: any) {
    this.setSelectedValue(cost_centre, 'cost_centre');
    this._companyServ.getCostCentreEmailAddresses(cost_centre.COSTCENTREID).subscribe((response) => {
      this.dataSource_Cost_Centre_Email_Addresses = new MatTableDataSource(response);
      if (response.length == 0) {
        this._snackBar.success('Cost Centre has no assigned email');
      }
    })
  }
  getDivisionEmailAddresses(division: any) {
    this.setSelectedValue(division, 'division');
    this._companyServ.getDivisionEmailAddresses(division.DIVISIONID).subscribe((response) => {
      this.dataSource_Division_Email_Addresses = response;
      if (response.length == 0) {
        this._snackBar.success('Division has no assigned email');
      }
    })
  }
  insertEmail(data?: any) {
    if (this.setup_selected_value) {
      const dialogRef = this.dialog.open(SetupInsertEmailComponent, {
        data: this.setup_selected_value,
        panelClass: 'modal-sm'
      });
      dialogRef.afterClosed().subscribe(
        (res) => {
          if (res) {
            this._snackBar.success(' Email Assigned Successfully');
            this.emailsRefresh();
          }
        }
      );
    } else {
      this._snackBar.error(' Please Select Option');
    }
  }
  updateEmailConfig(data?: any) {
    this.setup_selected_value.update = data;
    if (this.setup_selected_value) {
      const dialogRef = this.dialog.open(SetupInsertEmailComponent, {
        data: this.setup_selected_value,
        panelClass: 'modal-sm'
      });
      dialogRef.afterClosed().subscribe(
        (res) => {
          this.setup_selected_value.update = false;
          if (res) {
            this._snackBar.success(' Notification Updated Successfully');
            this.emailsRefresh();
          }
        }
      );
    } else {
      this._snackBar.error(' Please Select Option');
    }
  }
  setSelectedEmail(data?: any) {
    this.selected_email_address = data
  }
  private setSelectedValue(data: any, setfor: any) {
    data.SETFOR = setfor;
    this.setup_selected_value = data;
  }
  private emailsRefresh() {
    switch (this.setup_selected_value.SETFOR) {
      case 'cost_centre':
        this.getCostCentreEmailAddresses(this.setup_selected_value);
        break;
      case 'customer':
        this.getCustomerEmailAddresses(this.setup_selected_value);
        break;
      case 'account':
        this.getAccountEmailAddresses(this.setup_selected_value);
        break;
      case 'division':
        this.getDivisionEmailAddresses(this.setup_selected_value);
        break;
      default:
        console.log("No such day exists!");
        break;
    }
  }
  openAddBillingAccount(data?: any) {

    this._billingServ.getAccountBilling(JSON.stringify(data.ACCOUNTID)).subscribe((response: any) => {
      if (response[0]) {
        this.dialog.open(AddBillingAccountComponent, {
          data: response[0],
          panelClass: 'modal-sm'
        });
      } else {
        this._billingServ.getAccountByID(JSON.stringify(data.ACCOUNTID)).subscribe((response: any) => {
          if (response[0]) {
            this.dialog.open(AddBillingAccountComponent, {
              data: response[0],
              panelClass: 'modal-sm'
            });
          }
        });
      }
    })
  }
}


