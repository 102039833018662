import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BillingAccountsService } from 'src/app/services/billing-accounts/billing-accounts.service';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
@Component({
  selector: 'app-add-billing-account',
  templateUrl: './add-billing-account.component.html',
  styleUrls: ['./add-billing-account.component.css']
})
export class AddBillingAccountComponent {




  assignDesc(val: any) {
    let result = this.billingTypeOptions.find(function (item) {
      return item.name == val;
    });
    this.accountBillingForm.controls['BILLINGDESCRIPTION'].setValue(result.value);
  }

  constructor(
    public dialogRef: MatDialogRef<AddBillingAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _billingServ: BillingAccountsService,
    private _snackBar: SnackbarsService,
  ) {
    //Add 'implements OnInit' to the class.
    // this.data.BillingType = this.data.BillingType ? this.data.BillingType : 'NA';
    this.accountBillingForm = this.formBuilder.group({
      COMPANYID: [this.data.COMPANYID ? this.data.COMPANYID : 0, Validators.required],
      COMPANYNAME: [this.data.COMPANYNAME ? this.data.COMPANYNAME : '', Validators.required],
      CUSTOMERNAME: [this.data.CUSTOMERNAME ? this.data.CUSTOMERNAME : '', Validators.required],
      CUSTOMERID: [this.data.CUSTOMERID ? this.data.CUSTOMERID : 0, Validators.required],
      AS400CUSTOMERID: [this.data.AS400CUSTOMERID ? this.data.AS400CUSTOMERID : '', Validators.required],
      AS400ACCOUNTID: [this.data.AS400ACCOUNTID ? this.data.AS400ACCOUNTID : 0, Validators.required],
      AS400COMPANYID: [this.data.AS400CompanyId ? this.data.AS400CompanyId : 0, Validators.required],
      ACCOUNTID: [this.data.ACCOUNTID ? this.data.ACCOUNTID : 0, Validators.required],
      ACCOUNTNAME: [this.data.ACCOUNTNAME ? this.data.ACCOUNTNAME : '', Validators.required],
      BILLINGID: [this.data.BillingId ?? this.data.BillingId ],
      BILLINGDESCRIPTION: [this.data.BillingDescription ? this.data.BillingDescription : 'Not Applicable'],
      QTY: [this.data.Qty ? this.data.Qty : 0],
      BILLINGTYPE: [this.data.BillingType ? this.data.BillingType.replace(/\s/g, "") : 'NA'],
      FIRSTCHECKDAY: [this.data.FirstCheckDay ? this.data.FirstCheckDay : 0],
      AMOUNT: [this.data.Amount ? this.data.Amount : 0.00],
      NOTACTIVE: [this.data.active],
    });
  }
  accountBillingForm: any;

  billingTypeOptions: any[] = [
    { "name": "NA", "value": 'Not Applicable' },
    { "name": "FR", "value": 'Fuel Remote Homebase Monthly Fee' },
    { "name": "FC", "value": 'Fuel Manager Monthly Fee By Vehicle Count' },
    { "name": "FW", "value": 'Fuel Remote Web Monthly Fee' },
    { "name": "VU", "value": 'Vehicle Usage' },
    { "name": "LF", "value": 'Legal Fee ' },
    { "name": "FA", "value": 'Fuel Manager Anual Fee' },
    { "name": "FM", "value": 'Fuel Manager Monthly Fee' },
    { "name": "BS", "value": 'Broker Bueau Service Monthly Fee By Vehicle Count' },
    { "name": "BC", "value": ' Bueau Service Monthly Fee By Vehicle Count' },
    { "name": "SF", "value": ' Bueau Set Up Fee' },
    { "name": "BF", "value": ' Bueau Service Monthly Fee' },
  ];

  account_billing: any = null;
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }



  onSubmit() {
    this._billingServ.createUpdateAccountBilling(this.accountBillingForm.value).subscribe((response: any) => {
      if (response.rowsAffected > 0) {
        this._snackBar.success('Account Billing Updated!');
      } else {
        this._snackBar.errorRetry('Account Billing Failed To Update, Please Try Again!');
      }
    });
  }

  private isEmpty(value: string) {
    return value == null || (typeof value === "string" && value.trim().length === 0);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
