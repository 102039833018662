<div class="myModal" >
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="lft">
        <p>Edit Record</p>
        <p>ID: {{transaction.TRANSACTIONID}}</p>
      </div>
      <div class="rgt">
        <button mat-flat-button (click)="closeModal()">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>

    <div class="modal-contain">
        <div class="myuitabs">
          <div class="lft">
            <button mat-stroked-button [ngClass]="tab==='1'?'active':''" (click)="selectTab('1')" type="button"><p>Tab 1</p></button>
            <button mat-stroked-button [ngClass]="tab==='2'?'active':''" (click)="selectTab('2')" type="button"><p>Tab 2</p></button>
            <button mat-stroked-button [ngClass]="tab==='3'?'active':''" (click)="selectTab('3')" type="button"><p>Tab 3</p></button>
            <button mat-stroked-button [ngClass]="tab==='4'?'active':''" (click)="selectTab('4')" type="button"><p>Tab 4</p></button>
          </div>
          
        </div>
      <form class="myform" >
        <ng-container *ngIf="tab==='1'">
          <div class="row">
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Vehicle Id</mat-label>
                    <input matInput type="number" value={{transaction.VEHICLEID}} (keyup)="validateInput($event.target, 'VEHICLEID')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Registration No</mat-label>
                    <input matInput value={{transaction.REGISTRATIONNO}} (keyup)="validateInput($event.target, 'REGISTRATIONNO')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Fleet No</mat-label>
                    <input matInput value={{transaction.FLEETNO}} (keyup)="validateInput($event.target, 'FLEETNO')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <div class="row">
                <div class="col-12 col-md-12">
                  <mat-form-field >
                      <mat-label>Date Time</mat-label>
                      <input matInput value={{transaction.DATETIME}} (keyup)="validateInput($event.target, 'DATETIME')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Fuel Station Name</mat-label>
                    <input matInput value={{transaction.TOFUELSTATIONNAME}} (keyup)="validateInput($event.target, 'TOFUELSTATIONNAME')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Oil Company Name</mat-label>
                    <input matInput value={{transaction.OILCOMPANYNAME}} (keyup)="validateInput($event.target, 'OILCOMPANYNAME')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Driver Name</mat-label>
                  <input matInput value={{transaction.DRIVERNAME}} (keyup)="validateInput($event.target, 'DRIVERNAME')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Card No</mat-label>
                  <input matInput value={{transaction.CARDNO}} (keyup)="validateInput($event.target, 'CARDNO')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Voucher No</mat-label>
                  <input matInput value={{transaction.VOUCHERNO}} (keyup)="validateInput($event.target, 'VOUCHERNO')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Pump Attendant</mat-label>
                  <input matInput value={{transaction.PUMPATTENDANT}} (keyup)="validateInput($event.target, 'PUMPATTENDANT')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <div class="row">
                <div class="col-12 col-md-6">
                  <mat-form-field >
                      <mat-label>Current Odo</mat-label>
                      <input matInput type="number" value={{transaction.CURRENTODO}} (keyup)="validateInput($event.target, 'CURRENTODO')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                  <mat-form-field >
                      <mat-label>Previous Odo</mat-label>
                      <input matInput type="number" value={{transaction.PREVIOUSODO}} (keyup)="validateInput($event.target, 'PREVIOUSODO')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="row">
                <div class="col-12 col-md-6">
                  <mat-form-field >
                      <mat-label>Price Per Litres</mat-label>
                      <input matInput type="number" value={{transaction.PRICEPERLITRE}} (keyup)="validateInput($event.target, 'PRICEPERLITRE')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                  <mat-form-field >
                      <mat-label>Amount</mat-label>
                      <input matInput type="number" value={{transaction.AMOUNT}} (keyup)="validateInput($event.target, 'AMOUNT')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field >
                  <mat-label>Comment</mat-label>
                  <input matInput value={{transaction.COMMENT}} (keyup)="validateInput($event.target, 'COMMENT')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <div class="row">
                <div class="col-12 col-md-6">
                  <mat-form-field >
                      <mat-label>Trip Litres</mat-label>
                      <input matInput type="number" value={{transaction.TRIPLITRES}} (keyup)="validateInput($event.target, 'TRIPLITRES')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                  <mat-form-field >
                      <mat-label>Calc Litres</mat-label>
                      <input matInput type="number" value={{transaction.CALCLITRES}} (keyup)="validateInput($event.target, 'CALCLITRES')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="row">
                <div class="col-12 col-md-6">
                  <mat-form-field >
                      <mat-label>Trip KM</mat-label>
                      <input matInput type="number" value={{transaction.TRIPKM}} (keyup)="validateInput($event.target, 'TRIPKM')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                  <mat-form-field >
                      <mat-label>Calc KM</mat-label>
                      <input matInput type="number" value={{transaction.CALCKM}} (keyup)="validateInput($event.target, 'CALCKM')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
              </div>
            </div>
          
          </div>
        </ng-container>
        <ng-container *ngIf="tab==='2'">
          <div class="row">
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>AS400 Cost Centre Id</mat-label>
                    <input matInput value={{transaction.AS400COSTCENTREID}} (keyup)="validateInput($event.target, 'AS400COSTCENTREID')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Cost Centre Name</mat-label>
                    <input matInput value={{transaction.COSTCENTRENAME}} (keyup)="validateInput($event.target, 'COSTCENTRENAME')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Division Id</mat-label>
                    <input matInput type="number" value={{transaction.DivisionId}} (keyup)="validateInput($event.target, 'DivisionId')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Division Name</mat-label>
                    <input matInput value={{transaction.DivisionName}} (keyup)="validateInput($event.target, 'DivisionName')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>MVA Number</mat-label>
                    <input matInput type="number" value={{transaction.MVA_NUMBER}} (keyup)="validateInput($event.target, 'MVA_NUMBER')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>From Fuel Station Id</mat-label>
                    <input matInput type="number" value={{transaction.FROMFUELSTATIONID}} (keyup)="validateInput($event.target, 'FROMFUELSTATIONID')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Company Id</mat-label>
                    <input matInput type="number" value={{transaction.COMPANYID}} (keyup)="validateInput($event.target, 'COMPANYID')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Company Name</mat-label>
                    <input matInput value={{transaction.COMPANYNAME}} (keyup)="validateInput($event.target, 'COMPANYNAME')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Customer Id</mat-label>
                    <input matInput value={{transaction.CUSTOMERID}} (keyup)="validateInput($event.target, 'CUSTOMERID')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>AS400 Customer Id</mat-label>
                    <input matInput value={{transaction.AS400CUSTOMERID}} (keyup)="validateInput($event.target, 'AS400CUSTOMERID')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Transaction Id</mat-label>
                    <input matInput type="number" value={{transaction.TRANSACTIONID}} (keyup)="validateInput($event.target, 'TRANSACTIONID')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Account Id</mat-label>
                    <input matInput type="number" value={{transaction.ACCOUNTID}} (keyup)="validateInput($event.target, 'ACCOUNTID')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>AS400 Account Id</mat-label>
                    <input matInput value={{transaction.AS400ACCOUNTID}} (keyup)="validateInput($event.target, 'AS400ACCOUNTID')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Account Name</mat-label>
                    <input matInput value={{transaction.ACCOUNTNAME}} (keyup)="validateInput($event.target, 'ACCOUNTNAME')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Cost Centre Id</mat-label>
                  <input matInput type="number" value={{transaction.COSTCENTREID}} (keyup)="validateInput($event.target, 'COSTCENTREID')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Fuel Station Name</mat-label>
                  <input matInput value={{transaction.TOFUELSTATIONNAME}} (keyup)="validateInput($event.target, 'TOFUELSTATIONNAME')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>To Fuel Station Id</mat-label>
                  <input matInput type="number" value={{transaction.TOFUELSTATIONID}} (keyup)="validateInput($event.target, 'TOFUELSTATIONID')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Town</mat-label>
                  <input matInput value={{transaction.TOWN}} (keyup)="validateInput($event.target, 'TOWN')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Fuel Company Id</mat-label>
                  <input matInput type="number" value={{transaction.FUELCOMPANYID}} (keyup)="validateInput($event.target, 'FUELCOMPANYID')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Driver Id</mat-label>
                  <input matInput type="number" value={{transaction.DRIVERID}} (keyup)="validateInput($event.target, 'DRIVERID')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Card No Id</mat-label>
                  <input matInput type="number" value={{transaction.CARDNOID}} (keyup)="validateInput($event.target, 'CARDNOID')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>AS400 Company Id</mat-label>
                  <input matInput value={{transaction.AS400CompanyId}} (keyup)="validateInput($event.target, 'AS400CompanyId')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Invoice No</mat-label>
                  <input matInput value={{transaction.VOUCHERNO}} (keyup)="validateInput($event.target, 'VOUCHERNO')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Order No</mat-label>
                  <input matInput value={{transaction.ORDERNO}} (keyup)="validateInput($event.target, 'ORDERNO')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="tab==='3'">
          <div class="row">
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Loss Litres</mat-label>
                    <input matInput type="number" value={{transaction.LOSSLITRES}} (keyup)="validateInput($event.target, 'LOSSLITRES')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Loss Amount</mat-label>
                    <input matInput type="number" value={{transaction.LOSSAMOUNT}} (keyup)="validateInput($event.target, 'LOSSAMOUNT')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Litres At Norm</mat-label>
                    <input matInput type="number" value={{transaction.LITRESATNORM}} (keyup)="validateInput($event.target, 'LITRESATNORM')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Consumption Km/L</mat-label>
                    <input matInput type="number" value={{transaction.CONSUMPTIONKML}} (keyup)="validateInput($event.target, 'CONSUMPTIONKML')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Consumption L/100</mat-label>
                    <input matInput type="number" value={{transaction.CONSUMPTIONL100}} (keyup)="validateInput($event.target, 'CONSUMPTIONL100')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Perc Loss</mat-label>
                    <input matInput type="number" value={{transaction.CONSUMPTIONL100}} (keyup)="validateInput($event.target, 'CONSUMPTIONL100')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Horse Trailer</mat-label>
                    <input matInput value={{transaction.HORSETRAILER}} (keyup)="validateInput($event.target, 'HORSETRAILER')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Litres To Trailer</mat-label>
                    <input matInput type="number" value={{transaction.LITRESTOTRAILER}} (keyup)="validateInput($event.target, 'LITRESTOTRAILER')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Hours To Trailer</mat-label>
                    <input matInput type="number" value={{transaction.HOURSTOTRAILER}} (keyup)="validateInput($event.target, 'HOURSTOTRAILER')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Pickup Type</mat-label>
                    <input matInput value={{transaction.PICKUPTYPE}}  (keyup)="validateInput($event.target, 'PICKUPTYPE')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Tel</mat-label>
                    <input matInput type="number" value={{transaction.Tel}} (keyup)="validateInput($event.target, 'Tel')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field >
                    <mat-label>Norm</mat-label>
                    <input matInput type="number" value={{transaction.NORM}} (keyup)="validateInput($event.target, 'NORM')" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Lper Hour</mat-label>
                  <input matInput type="number" value={{transaction.LPERHOUR}} (keyup)="validateInput($event.target, 'LPERHOUR')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Month Exceed</mat-label>
                  <input matInput type="number" value={{transaction.MONTHEXCEED}} (keyup)="validateInput($event.target, 'MONTHEXCEED')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Customer Norm</mat-label>
                  <input matInput type="number" value={{transaction.CUSTOMERNORM}} (keyup)="validateInput($event.target, 'CUSTOMERNORM')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>CPK</mat-label>
                  <input matInput type="number" value={{transaction.CPK}} (keyup)="validateInput($event.target, 'CPK')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>Tank Capacity</mat-label>
                  <input matInput type="number" value={{transaction.TANKCAPACITY}} (keyup)="validateInput($event.target, 'TANKCAPACITY')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>CPK Norm</mat-label>
                  <input matInput type="number" value={{transaction.CPK_NORM}} (keyup)="validateInput($event.target, 'CPK_NORM')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12 col-md-6">
                  <mat-form-field >
                      <mat-label>Create Date Time</mat-label>
                      <input matInput value={{transaction.CREATEDATETIME}}  (keyup)="validateInput($event.target, 'CREATEDATETIME')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                  <mat-form-field >
                      <mat-label>Modify Date Time</mat-label>
                      <input matInput value={{transaction.MODIFYDATETIME}} (keyup)="validateInput($event.target, 'MODIFYDATETIME')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
              </div>
              
            </div>
            
               
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>From Location</mat-label>
                  <input matInput value={{transaction.FromLoc}} (keyup)="validateInput($event.target, 'FromLoc')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <mat-form-field >
                  <mat-label>To Location</mat-label>
                  <input matInput value={{transaction.ToLoc}} (keyup)="validateInput($event.target, 'ToLoc')" placeholder="Ex. 1234">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12 col-md-6">
                  <mat-form-field >
                      <mat-label>Seal No 1</mat-label>
                      <input matInput value={{transaction.SealNo1}} (keyup)="validateInput($event.target, 'SealNo1')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                  <mat-form-field >
                      <mat-label>Seal 2</mat-label>
                      <input matInput value={{transaction.SealNo2}} (keyup)="validateInput($event.target, 'SealNo2')" placeholder="Ex. 1234">
                  </mat-form-field>
                </div>
              </div>
              
            </div>
           
          </div>
        </ng-container>
        <ng-container *ngIf="tab==='4'">
            <div class="row">
                <div class="col-12 col-md-3">
                  <div class="card055">
                    <p class="title">Set Tank Full</p>
                    <div class="butons">
                        <button mat-stroked-button (click)="selectIsTankFull(1)" [ngClass]="isSetTankFull===1?'active':''"><p>Yes</p></button>
                        <button mat-stroked-button (click)="selectIsTankFull(0)" [ngClass]="isSetTankFull===0?'active':''"><p>No</p></button> 
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="card055">
                    <p class="title">New Import</p>
                    <div class="butons">
                        <button mat-stroked-button (click)="selectIsNewImport(1)" [ngClass]="isNewImport===1?'active':''"><p>Yes</p></button>
                        <button mat-stroked-button (click)="selectIsNewImport(0)" [ngClass]="isNewImport===0?'active':''"><p>No</p></button> 
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="card055">
                    <p class="title">Mail Sent</p>
                    <div class="butons">
                        <button mat-stroked-button (click)="selectIsMailSent(1)" [ngClass]="isMailSent===1?'active':''"><p>Yes</p></button>
                        <button mat-stroked-button (click)="selectIsMailSent(0)" [ngClass]="isMailSent===0?'active':''"><p>No</p></button> 
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="card055">
                    <p class="title">Internal</p>
                    <div class="butons">
                        <button mat-stroked-button (click)="selectIsInternal(1)" [ngClass]="isInternal===1?'active':''"><p>Yes</p></button>
                        <button mat-stroked-button (click)="selectIsInternal(0)" [ngClass]="isInternal===0?'active':''"><p>No</p></button> 
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="card055">
                    <p class="title">Ingore Calculations</p>
                    <div class="butons">
                        <button mat-stroked-button (click)="selectIsIgnoreCalculation(1)" [ngClass]="isIgnoreCalculations===1?'active':''"><p>Yes</p></button>
                        <button mat-stroked-button (click)="selectIsIgnoreCalculation(0)" [ngClass]="isIgnoreCalculations===0?'active':''"><p>No</p></button> 
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="card055">
                    <p class="title">Matched</p>
                    <div class="butons">
                        <button mat-stroked-button (click)="selectIsMatched(1)" [ngClass]="isMatched===1?'active':''"><p>Yes</p></button>
                        <button mat-stroked-button (click)="selectIsMatched(0)" [ngClass]="isMatched===0?'active':''"><p>No</p></button> 
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="card055">
                    <p class="title">New Odo</p>
                    <div class="butons">
                        <button mat-stroked-button (click)="selectIsNewOdo(1)" [ngClass]="isNewOdo===1?'active':''"><p>Yes</p></button>
                        <button mat-stroked-button (click)="selectIsNewOdo(0)" [ngClass]="isNewOdo===0?'active':''"><p>No</p></button> 
                    </div>
                  </div>
                </div>
            </div>
        </ng-container>
        <div class="row">
          <div class="col-12">
            <button class="mybtn" (click)="saveChanges()" type="button" mat-flat-button color="primary">Save Changes</button>
          </div>
        </div>
      </form>
    </div>
  </div>
