import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CacheService } from '../utilities/cache/cache.service';
const CACHE_KEY = {
  httpManagedVehiclesCache: 'httpManagedVehiclesCache',
  httpNotManagedVehiclesCache: 'httpNotManagedVehiclesCache',
  httpAwaitingVehiclesCache: 'httpAwaitingVehiclesCache',
  httpVehiclesCountsCache: 'httpTransactionCountsCache',
  httpVehicleDetailsCache: 'httpVehicleDetailsCache',
};
@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  constructor(private http: HttpClient, private _stateServ: CacheService) { }

  private service_base =
    'https://zedadev.apigw-aw-eu.webmethods.io/gateway/IFM/1.0/';

  private headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });

  addVehicle(vehicleDetails: any) {
    const url = this.service_base + 'insertVehicle';
    const result = this.http.post<any>(url, vehicleDetails, {
      headers: this.headers,
    });
    return result;
  }

  getVehicleAccount(registration: string) {
    const url =
      this.service_base +
      'getVehicleAccount?RegistrationNumber=' +
      registration;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
  }

  getAllVehices(id: any) {
    const url = this.service_base + 'getAllVehicle?companyId=' + id;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
  }

  updateVehicle(vehicleDetails: any) {
    const url = this.service_base + 'updateVehicleByVehicleID';
    const result = this.http.post<any>(
      url,
      { inputs: vehicleDetails },
      {
        headers: this.headers,
      }
    );
    return result;
  }

  getVehicleByRegistration(registration: string) {
    const url =
      this.service_base +
      'getVehicleByRegNo?RegistrationNumber=' +
      registration;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
  }

  getVehicleByCompany(companyId: any) {
    const url =
      this.service_base + 'getVehicleByCompanyId?COMPANYID=' + companyId;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return this._stateServ.manageDataState(
      result,
      CACHE_KEY.httpManagedVehiclesCache
    );
  }

  getVehicleByID(vehicleId: string) {
    return null;
  }
  getNotManagedVehicles(companyName: string) {
    const url =
      this.service_base + 'getNotManagedVehicle?CompanyName=' + companyName;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return this._stateServ.manageDataState(
      result,
      CACHE_KEY.httpNotManagedVehiclesCache
    );
  }

  getAwaitingVehiclesByCompanyId(id: any): Observable<any> {
    const url = this.service_base + 'getNoVehicleByCompanyId?CompanyId=' + id;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return this._stateServ.manageDataState(
      result,
      CACHE_KEY.httpAwaitingVehiclesCache
    );
  }

  getVehicleKPI(id: any) {
    const url = this.service_base + '	getVehicleKPI?companyId=' + id;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return this._stateServ.manageDataState(
      result,
      CACHE_KEY.httpVehiclesCountsCache
    );
  }

  getVehicleDetails(RegistrationNumber: any) {
    const url =
      this.service_base +
      '	getVehicleDetail?RegistrationNo=' +
      RegistrationNumber;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    // return this._stateServ.manageDataState(
    //   result,
    //   CACHE_KEY.httpVehicleDetailsCache
    // );
    return result;
  }

  getVehicleModel(registrationNo: any) {
    const url =
      '	/company/getVehicleModel/' +
      registrationNo;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
  }
}
