import {Component,EventEmitter,Inject,Output,} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from '../../../services/companies/company.service';
import { StorageService } from 'src/app/services/storage/storage.service';
@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.css'],
})
export class SelectCompanyComponent {
  @Output() selectedCompany = new EventEmitter();
  selected_company_name: any = null;
  constructor(
    public dialogRef: MatDialogRef<SelectCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private companyService: CompanyService,
    private _storageServ: StorageService,
  ) {}
  closeModal(): void {
    this.dialogRef.close();
  }

  // Fecthing Companies
  fetch_response = 'loading';
  fetch_data: any = [];
  raw_data: any = [];
  fetch_companies() {
    this.fetch_response = 'loading';
    this.companyService.getUserCompanies(this._storageServ.getUserId()).subscribe({
      next: (data: any) => {
        if (data.results.length > 0) {
          this.fetch_response = 'success';
          this.fetch_data = data.results;
          this.raw_data = data.results;
        } else {
          this.fetch_response = 'no_results';
          if(data.status.message == 'ConnectionError'){
            this.fetch_response = 'network_err';
          }
        }
      },
      error: (error:any) => {
        this.fetch_response = 'network_err';
      },
    });
  }
  selectCompany(r: any) {
    sessionStorage.setItem('company_id', r.COMPANYID);
    sessionStorage.setItem('company_selected', r.COMPANYNAME);
    sessionStorage.setItem('AVISWESBANKID', r.AvisWesbankId ? r.AvisWesbankId.replaceAll(' ', '') : '');
    this.closeModal();
    window.location.reload();
  }

  ngOnInit() {
    this.fetch_companies();
    if(this.companyService.getCompanyName()){
      this.selected_company_name = this.companyService.getCompanyName();
    }

  }

  handle_search(event: any) {
    var value = event.value;
    if (this.raw_data.length > 0) {
      const results = this.raw_data.filter((x: any) => {
        return x.COMPANYNAME.toLowerCase().includes(value.toLowerCase());
      });
      this.fetch_data = results;
    }
  }
}
