
<div class="mywrapper">

  <div class="select-company-main">
    <div class="contain">


      <div *ngIf="fetch_response=='success'">
        <div class="htitle FxLine">
          Select company you want to work with
        </div>
        <div class="searchbox">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input type="text" (keyup)="handle_search($event.target)" placeholder="Search something here"/>
        </div>
        <div class="cardscontainer">
            <div class="mycard-01" *ngFor="let r of fetch_data">
              <div class="lft">
                <mat-icon>business</mat-icon>
                <div class="titles">
                  <p>{{r.COMPANYNAME }}</p>
                  <p><i class=""></i></p>
                </div>
              </div>
              <div class="rgt">
                <button mat-flat-button class="" color="primary"
                      (click)="selectCompany(r)">Select
                </button>
              </div>
            </div>
            <p class="nomore">No more results</p>
        </div>
      </div>
      <div class="myloader" *ngIf="fetch_response=='loading'">
          <div class="wrapp">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              <p>Please wait while fetching companies</p>
          </div>
      </div>
      <div class="myfeedback" *ngIf="fetch_response=='network_err'">
          <div class="wrapp">
              <i class="fa-solid fa-signal"></i>
              <p>Ops, Check your internet connectivity.</p>
              <div class="btns">
                  <button mat-flat-button class="" (click)="fetch_companies()" color="accent">Retry</button>
                  <button mat-flat-button class=""  color="primary" (click)="logout()">Logout</button>
              </div>
          </div>
      </div>
      <div class="myfeedback" *ngIf="fetch_response=='no_results'">
          <div class="wrapp">
              <i class="fa-solid fa-circle-info"></i>
              <p>No results, you do not have companies.</p>
              <div class="btns">
                  <button mat-flat-button class="" (click)="fetch_companies()" color="accent">Retry</button>
                  <button mat-flat-button class=""  color="primary" (click)="logout()">Logout</button>
              </div>
          </div>
      </div>

      <div class="closefooter">
          <button mat-stroked-button (click)="logout()">Logout</button>
      </div>
    </div>
  </div>
</div>
