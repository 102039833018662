
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/companies/company.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-company-config',
  templateUrl: './company-config.component.html',
  styleUrls: ['./company-config.component.css']
})
export class CompanyConfigComponent {
  constructor(
    public dialogRef: MatDialogRef<CompanyConfigComponent>,
    private _companyServ: CompanyService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, { duration: 5000, panelClass: type });
  }

  ngOnInit(): void {
    this.getCompanyCustomers();
  }

  closeModal(): void {
    this.dialogRef.close({event:''});
  }

  getCompanyCustomersResponse = '';
  CustomerList = [];
  RawData = [];
  getCompanyCustomers() {
    if(this.data.form.COMPANYID === ""){
      return;
    }
    this.getCompanyCustomersResponse = 'loading';
    this._companyServ.getCompanyCustomers(this.data.form.COMPANYID).subscribe({
      next: (data: any) => {
        if (data.results && data.results.length > 0) {
          this.getCompanyCustomersResponse = 'success';
          this.CustomerList = data.results;
          this.RawData = data.results;
        }else{
          this.getCompanyCustomersResponse = 'no_results';
        }
      
      },
      error: (error: any) => {
        this.getCompanyCustomersResponse = 'network_err';
        this.openSnackBar(error.message, 'Retry', 'error');
      },
    });
  }

  search_string = '';
  handle_search(event: any) {
    this.search_string = event.value;
    if (this.RawData.length < 1) {
      return;
    }
    const results = this.RawData.filter((x: any) => {
    const CUSTOMERNAME = x.CUSTOMERNAME?x.CUSTOMERNAME.toString():"";
    const AS400CUSTOMERID = x.AS400CUSTOMERID?x.AS400CUSTOMERID.toString():"";
    return (
      CUSTOMERNAME.toLowerCase().includes(
        this.search_string.toLowerCase()
      ) ||
      AS400CUSTOMERID.toLowerCase().includes(
        this.search_string.toLowerCase()
      )
    );
    });
    this.CustomerList = results;
  }

  // isCustomerExists(companyId: any){
  //   const index  = this.CustomerList.findIndex((x:any) => x.COMPANYID.toString() === companyId.toString());
  //   if(index != -1){
  //     return true;
  //   }
  //   return false;
  // }

  // getToTownResponse = '';
  // getToTownData = [];
  // getToTowns() {
  //   this.getToTownResponse = 'loading';
  //   this._companyServ.getToTowns().subscribe({
  //     next: (data: any) => {
  //       if (data.results && data.results.length > 0) {
  //         this.getToTownResponse = 'success';
  //         this.getToTownData = data.results;
  //       } else {
  //         this.getToTownResponse = 'no_results';
  //         this.openSnackBar('No Results', 'Okay', 'success');
  //       }
  //     },
  //     error: (error: any) => {
  //       this.getToTownResponse = 'network_err';
  //       this.openSnackBar(error.message, 'Retry', 'error');
  //     },
  //   });
  // }

  // getFromTownResponse = '';
  // getFromTownData = [];
  // getFromTowns() {
  //   this.getFromTownResponse = 'loading';
  //   this._companyServ.getToTowns().subscribe({
  //     next: (data: any) => {
  //       if (data.results && data.results.length > 0) {
  //         this.getFromTownResponse = 'success';
  //         this.getFromTownData = data.results;
  //       } else {
  //         this.getFromTownResponse = 'no_results';
  //         this.openSnackBar('No Results', 'Okay', 'success');
  //       }
  //     },
  //     error: (error: any) => {
  //       this.getFromTownResponse = 'network_err';
  //       this.openSnackBar(error.message, 'Retry', 'error');
  //     },
  //   });
  // }

  onChangeValue(fieldName: string, value: any) {
    this.data.form[fieldName] = value;
  }
  selectDrivers(fieldName: string, value: any) {
    this.data.form.ASSIGNDRIVERSIMPORT = '0';
    this.data.form.UseAS400Drivers = '0';
    this.data.form.INUSE = '0';

    this.data.form[fieldName] = value;
  }

  isValidForm(){
    if(this.data.form.COMPANYNAME.length < 1){
      (document.querySelector("input[name=COMPANYNAME]") as HTMLInputElement).focus();
      return false;
    }
    // if(this.data.form.ContactPerson.length < 1){
    //   (document.querySelector("input[name=ContactPerson]") as HTMLInputElement).focus();
    //   return false;
    // }
    // if(this.data.form.Telephone.length < 1){
    //   (document.querySelector("input[name=Telephone]") as HTMLInputElement).focus();
    //   return false;
    // }
    // if(this.data.form.Cell.length < 1){
    //   (document.querySelector("input[name=Cell]") as HTMLInputElement).focus();
    //   return false;
    // }
    if(this.data.form.CURRENCYSYMBOL.length < 1){
      (document.querySelector("mat-select[name=CURRENCYSYMBOL]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.TRAILING.length < 1){
      (document.querySelector("mat-select[name=TRAILING]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.FUELMEASUREID.length < 1){
      (document.querySelector("mat-select[name=FUELMEASUREID]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.PERCUPPERLIMIT.length < 1){
      (document.querySelector("input[name=PERCUPPERLIMIT]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.PERCLOWERLIMIT.length < 1){
      (document.querySelector("input[name=PERCLOWERLIMIT]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.TANKEXCEED.length < 1){
      (document.querySelector("input[name=TANKEXCEED]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.LperHourCalc.length < 1){
      (document.querySelector("input[name=LperHourCalc]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.DAYSTOEDIT.length < 1){
      (document.querySelector("input[name=DAYSTOEDIT]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.PERCNOLOSS.length < 1){
      (document.querySelector("input[name=PERCNOLOSS]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.HighOdoValue.length < 1){
      (document.querySelector("input[name=HighOdoValue]") as HTMLInputElement).focus();
      return false;
    }
    return true;
  }

  addCompanyResponse = '';
  addCompany() {
    if(!this.isValidForm()){
      return;
    }
    let payload = this.data.form;
    this.addCompanyResponse = 'loading';
    
    this._companyServ.insertCompany(payload).subscribe({
      next: (data: any) => {
        if(data.status.message == "success"){
          this.addCompanyResponse = 'success';
          this.dialogRef.close({event:'create', payload});
          this.openSnackBar('Successfully created the company '+payload.COMPANYNAME, 'Okay', 'success');
        }else{
          this.addCompanyResponse = 'network_err';
          if(data.status.message.includes('Company already exists')){
            this.openSnackBar('Company already exists', 'Okay', 'error');
            (document.querySelector("input[name=COMPANYNAME]") as HTMLInputElement).focus();
          }else{
            this.openSnackBar(data.status.message, 'Okay', 'success')
          }
        }
      },
      error: (error: any) => {
        this.openSnackBar('Check internet connectivity', 'Okay', 'error');
        this.addCompanyResponse = 'network_err';
      },
    });
  }

  
  updateCompanyResponse = ''; 
  updateCompany() {
    if(!this.isValidForm()){
      return;
    }
    let payload = this.data.form;
    this.updateCompanyResponse = 'loading';

    this._companyServ.updateCompany(payload).subscribe({
      next: (data: any) => {
        if(data.status.message == "success"){
          this.updateCompanyResponse = 'success';
          this.dialogRef.close({event:'create', payload});
          this.openSnackBar('Successfully updated the company '+payload.COMPANYNAME, 'Okay', 'success');
        }else{
          this.updateCompanyResponse = 'network_err';
          if(data.status.message.includes('Company already exists')){
            this.openSnackBar('Company already exists', 'Okay', 'error');
            (document.querySelector("input[name=COMPANYNAME]") as HTMLInputElement).focus();
          }else{
            this.openSnackBar(data.status.message, 'Okay', 'success')
          }
        }
      },
      error: (error: any) => {
        this.openSnackBar(error.message, 'Retry', 'error');
        this.updateCompanyResponse = 'network_err';
      },
    });
 
  }
}

