import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../../models/user';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // private service_base = "https://zedadev.apigw-aw-eu.webmethods.io/gateway/IFM/1.0/"

  // Local
  // public service_base = "http://127.0.0.1:8000/api/"
  // Live
  public service_base = "https://fms-api.lizzardtechnologies.co.za/api/"

  // private url = 'http://197.159.52.121:5555/restv2/validateUserCredentials?';

  headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin':'*'  });

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService
  ) {}

  authorize(user: User) {
    return this.http.get<any>(
      this.service_base+ 'ADValidation/' + user.username + '/' + user.password,
      {
        headers:this.headers
      }
    );
  }

  logout() {
    this.storageService.end();
  }
}

