<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="lft">
        <ng-container *ngIf="data.action == 'create'">
            <p>Create New User</p>
            <p>Add new company</p>
          </ng-container>
          <ng-container *ngIf="data.action == 'update'">
            <p>Update User</p>
            <p>{{data.form.USERNAME}}</p>
          </ng-container>
      </div>
      <div class="rgt">
        <button mat-flat-button (click)="closeModal()">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
  
    <div class="modal-contain">
      <form class="myform addnewuser" >
          <div class="userpp">
            <i class="fa-regular fa-user"></i>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field>
                <mat-label>Username</mat-label>
                <input
                    name="USERNAME"
                    (keyup)="onChangeValue('USERNAME', $event.target.value)"
                    [value]="data.form.USERNAME"
                    matInput
                    placeholder="E.g John Smith"
                    maxlength="50"
                />
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field>
                <mat-label>Email Address</mat-label>
                <input
                    name="EmailAddress"
                    (keyup)="onChangeValue('EmailAddress', $event.target.value)"
                    [value]="data.form.EmailAddress"
                    matInput
                    placeholder="E.g name.surname@avis.co.za"
                    maxlength="50"
                />
              </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field>
                    <mat-label>Email Signature Path (Optional)</mat-label>
                    <input
                        name="PATHOFEMAILSIGNATURE"
                        (keyup)="onChangeValue('PATHOFEMAILSIGNATURE', $event.target.value)"
                        [value]="data.form.PATHOFEMAILSIGNATURE"
                        matInput
                        placeholder="E.g D:\FuelManager\Signatures\yourname.jpg"
                        maxlength="500"
                    />
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-checkbox name="PerformanceReport" [checked]="data.form.PerformanceReport == '1'? true : false " (change)="onChangeValue('PerformanceReport', $event.checked? '1' : '0' )" color="primary">Include In Performance Report</mat-checkbox>
            </div>
            <div class="col-12">
                <mat-progress-bar mode="indeterminate"  *ngIf="response === 'loading'"></mat-progress-bar>
                <button class="mybtn" [disabled]="response === 'loading'? true : false" mat-flat-button color="primary" type="button" (click)="createUser()"  *ngIf="data.action == 'create'">
                    Create User
                </button>
                <button class="mybtn" [disabled]="response === 'loading'? true : false" mat-flat-button color="primary" type="button" (click)="updateUser()"  *ngIf="data.action == 'update'">
                    Update User
                </button>
            </div>
          </div>
  
      </form>
    </div>
  </div>
  