import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, startWith } from 'rxjs';
import { CacheService } from '../utilities/cache/cache.service';
const CACHE_KEY = {
  httpManagedTransactionsCache: 'httpManagedTransactionsCache',
  httpNotManagedTransactionsCache: 'httpNotManagedTransactionsCache',
  httpAwaitingTransactionsCache: 'httpAwaitingTransactionsCache',
  httpGetGetDictonariesCache: 'httpGetGetDictonariesCache',
  httpGetReconCache: 'httpGetReconCache',
  httpTransactionCountsCache: 'httpTransactionCountsCache',
};

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(private http: HttpClient, private _stateServ: CacheService) {}

  private service_base =
    'https://zedadev.apigw-aw-eu.webmethods.io/gateway/IFM/1.0/';

  headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });
  public PostFuelTransactions(payload: any, companyID: any, fileName: any) {
    // const url = '/restv2/postFuelTransactions';
    const url = this.service_base + 'postFuelTransactions';
    const result = this.http.post<any>(
      url,
      {
        FuelList: payload,
        company_Id: companyID,
        FileNameVal: fileName,
        requestType: 'Online',
      },
      {
        headers: this.headers,
      }
    );
    return result;
  }
  public FetchTransactions(TransactionCode: any, CompanyName: any) {
    const url =
      this.service_base +
      'getTransactionCount?TransactionCode=' +
      TransactionCode +
      '&CompanyName=' +
      CompanyName +
      '&Min=1' +
      '&Max=100000000';
    let result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
    if (CACHE_KEY.httpManagedTransactionsCache) {
      return this._stateServ.manageDataState(
        result,
        CACHE_KEY.httpManagedTransactionsCache
      );
    } else {
      this._stateServ.manageDataState(
        result,
        CACHE_KEY.httpManagedTransactionsCache
      );
      return result;
    }
  }
  getTransactionsCounts(CompanyName: any) {
    const url =
      this.service_base + 'getTransactionCountNoTrn?CompanyName=' + CompanyName;
    let result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
    if (CACHE_KEY.httpTransactionCountsCache) {
      return this._stateServ.manageDataState(
        result,
        CACHE_KEY.httpTransactionCountsCache
      );
    } else {
      this._stateServ.manageDataState(
        result,
        CACHE_KEY.httpTransactionCountsCache
      );
      return result;
    }
  }

  getDataDitionaries() {
    const url = this.service_base + 'getDataDictionaries';
    let result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
    if (CACHE_KEY.httpGetGetDictonariesCache)
      return this._stateServ.manageDataState(
        result,
        CACHE_KEY.httpGetGetDictonariesCache
      );
    else {
      this._stateServ.manageDataState(
        result,
        CACHE_KEY.httpGetGetDictonariesCache
      );
      return result;
    }
  }
  getFilesStatus(CompanyId: any) {
    const url = this.service_base + 'getReconReport?companyId=' + CompanyId;
    let result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
    if (CACHE_KEY.httpGetReconCache)
      return this._stateServ.manageDataState(
        result,
        CACHE_KEY.httpGetReconCache
      );
    else {
      this._stateServ.manageDataState(result, CACHE_KEY.httpGetReconCache);
      return result;
    }
  }
  deleteReconbyCompanyID(id: string): Observable<any> {
    const url = this.service_base + 'deleteRecountReportByCID?companyID=' + id;
    let result = this.http.delete<any>(url, {
      headers: this.headers,
    });
    return result;
  }

  getUploadedFiles() {
    return localStorage.getItem('uploaded_files');
  }

  addMinute(transaction: any) {
    const url = '/restv2/addXMin';
    const result = this.http.put<any>(url, transaction, {
      headers: this.headers,
    });
    return result;
  }

  removeCalculations(transactions: any) {
    const url = '/restv2/removeCalculations';
    const result = this.http.put<any>(url, transactions, {
      headers: this.headers,
    });
    return result;
  }
  mergeTransactions(transactions: any) {
    const url = this.service_base + 'combineTo1';
    return this.http.post<any>(url, transactions, {
      headers: this.headers,
    });
  }

  commitTransactions(transactions: any) {
    const url = this.service_base + 'updateNewImport';
    const result = this.http.post<any>(url, transactions, {
      headers: this.headers,
    });
    return result;
  }

  deleteLayout(id: any) {
    if (localStorage.getItem('layouts')) {
      const layouts = JSON.parse(localStorage.getItem('layouts') || '[]');
      const new_data = layouts.filter(function (x: any) {
        if (x.id !== id) {
          return x;
        }
      });
      localStorage.setItem('layouts', JSON.stringify(new_data));
      return 'success';
    } else {
      return 'success';
    }
  }
  addLayout(payload: any) {
    const layouts = JSON.parse(localStorage.getItem('layouts') || '[]');
    layouts.push(payload);
    localStorage.setItem('layouts', JSON.stringify(layouts));
    return 'success';
  }
  getLayouts(userId: any) {
    if (localStorage.getItem('layouts')) {
      const layouts = JSON.parse(localStorage.getItem('layouts') || '[]');
      if (layouts.length > 0) {
        return {
          results: layouts,
        };
      } else {
        return { results: [] };
      }
    } else {
      return { results: [] };
    }
  }

  public recalculateFuelTransactions(
    payload: any,
    companyID: any,
    fileName: any
  ) {
    const url = this.service_base + 'insertFuelTransaction';
    const result = this.http.post<any>(
      url,
      {
        FuelList: payload,
        company_Id: companyID,
        FileNameVal: fileName,
        requestType: 'Online',
      },
      {
        headers: this.headers,
      }
    );
    return result;
  }

  updateTankFull(payload: any) {
    const url = this.service_base + 'updateTankFull';
    const result = this.http.put<any>(
      url,
      {
        FuelTransaction: payload,
      },
      {
        headers: this.headers,
      }
    );
    return result;
  }

  deleteTransactions(payload: any) {
    const url = this.service_base + 'deleteTransactions';
    const result = this.http.delete<any>(url, {
      headers: this.headers,
      body: {
        FuelTransaction: payload,
      },
    });
    return result;
  }

  updateChanges(payload: any) {
    const url = this.service_base + 'saveTranscationsUpdates';
    const result = this.http.put<any>(
      url,
      {
        FuelTransactionInputs: payload,
      },
      {
        headers: this.headers,
      }
    );
    return result;
  }
  // /ArchiveTransactions
  archiveRestore(payload: any): Observable<any> {
    const url = this.service_base + 'ArchiveTransactions';

    const result = this.http.post<any>(url, payload, {
      headers: this.headers,
    });
    return result;
  }
}
