import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/companies/company.service';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
import { MailsService } from 'src/app/services/notifications/mails.service';
import { UserService } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-setup-insert-email',
  templateUrl: './setup-insert-email.component.html',
  styleUrls: ['./setup-insert-email.component.css']
})
export class SetupInsertEmailComponent {

  addDriverForm: any;
  constructor(
    public dialogRef: MatDialogRef<SetupInsertEmailComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackbar: SnackbarsService,
    private formBuilder: FormBuilder,
    private _UserServ: UserService,
    private _companyServ: CompanyService,
    private _mailServ: MailsService
  ) { }

  fullname = '';
  email_address = '';
  emailTypesControl: FormControl = new FormControl();
  emailAddressControl: FormControl = new FormControl();
  emailTypeControl: FormControl = new FormControl();

  selected_email: any;
  selected_mailType: any;
  mail_types: any[] = []
  email_addresses: any[] = []

  addEmailForm = this.formBuilder.group({
    COSTCENTREID: new FormControl('', Validators.required),
    CUSTOMERID: new FormControl('', Validators.required),
    DIVISIONID: new FormControl('', Validators.required),
    ACCOUNTID: new FormControl('', Validators.required),
    EMAILADDRESSID: ['', Validators.required],
    EMAILADDRESS: ['', Validators.required],
    MAILTYPEID: ['', Validators.required],
    MAILTYPEDESCRIPTION: ['', Validators.required],
    MAILFOR: [this.data.SETFOR],
  });

  ngOnInit(): void {
    this.getEmailTypes()
    this.getCompanyEmailAddresses(this.data.COMPANYID!)
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.data.update) {
      this.addEmailForm.controls['EMAILADDRESS'].setValue(this.data.update.EmailAddress);
      this.addEmailForm.controls['EMAILADDRESSID'].setValue(this.data.update.EmailAddressId);
      this.emailAddressControl.setValue(this.data.update.EmailAddress);
      this.emailTypesControl.setValue(this.data.update.MailTypeDescription);
      this.addEmailForm.controls['MAILTYPEID'].setValue(this.data.update.MailTypeId);
      this.addEmailForm.controls['MAILTYPEDESCRIPTION'].setValue(this.data.update.MailTypeDescription);
    }
  }

  getEmailTypes() {
    this._mailServ.getAllMailTypes().subscribe((response: any) => {
      this.mail_types = response.response
    });
  }

  getCompanyEmailAddresses(company_id: string) {
    this._mailServ.getAllCompanyEmailAdresses(company_id).subscribe((response: any) => {
      this.email_addresses = response.response
    });
  }

  displayFn(data: any): string {
    return data && data.EMAILADDRESS ? data.EMAILADDRESS : '';
  }

  validateEmail(email: any) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  validateEmailAddress(event: any) {
    const value = event.value;
    if (value.length < 50) {
      this.email_address = value;
    } else {
      this.email_address = value.substring(0, 50);
    }
  }

  response = '';
  response_data: any = null;

  onSubmit() {
    this._mailServ.InsertCompanyNotificationEmail(this.addEmailForm.value).subscribe((response: any) => {
      if (response)
        this.closeModal(this.addEmailForm.value);
    });
  }

  selectedEmailType(option: any) {
    this.addEmailForm.controls['MAILTYPEID'].setValue(option.MAILTYPEID);
    this.addEmailForm.controls['MAILTYPEDESCRIPTION'].setValue(option.MAILTYPEDESCRIPTION);
  }

  selectedEmail(option: any) {
    this.addEmailForm.controls['COSTCENTREID'].setValue(this.data.COSTCENTREID);
    this.addEmailForm.controls['CUSTOMERID'].setValue(this.data.CUSTOMERID);
    this.addEmailForm.controls['ACCOUNTID'].setValue(this.data.ACCOUNTID);
    this.addEmailForm.controls['DIVISIONID'].setValue(this.data.DIVISIONID);
    this.addEmailForm.controls['EMAILADDRESS'].setValue(option.EMAILADDRESS);
    this.addEmailForm.controls['EMAILADDRESSID'].setValue(option.EMAILADDRESSID);
  }

  closeModal(data?: any): void {
    if (data) {
      this.dialogRef.close(data);
    } else {
      this.dialogRef.close(false);
    }
  }

}
