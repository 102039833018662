<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>{{ vehicle_info.REGISTRATIONNO }}</p>
      <p *ngIf="vehicle_info_reponse === 'loading'">Loading, please wait...</p>
      <p *ngIf="vehicle_info_reponse === 'success'">
        {{ vehicle_info.VEHICLEMAKE + " " + vehicle_info.VEHICLERANGE }}
      </p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain">
    <div class="myuitabs">
      <div class="lft">
        <button mat-stroked-button [ngClass]="tab==='1'?'active':''" (click)="selectTab('1')" type="button"><p>Vehicle Details</p></button>
        <button mat-stroked-button [ngClass]="tab==='2'?'active':''" (click)="selectTab('2')" type="button"><p>Vehicle Driver</p></button>
      </div>
    </div>
    <ng-container *ngIf="tab==='1'">
      <form
        class="myform"
        [formGroup]="updateVehicleForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="row">
          <div class="col-4">
            <div class="card01010">
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Vehicle Id</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.VEHICLEID }}"
                      formControlName=""
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Company Id</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.COMPANYID }}"
                      [readonly]="true"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>AS400 Vehicle Id</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.AS400VEHICLEID }}"
                      formControlName="AS400VEHICLEID"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>

                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>MVA Number</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.MVA_Number }}"
                      formControlName="MVA_Number"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Registration No</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.REGISTRATIONNO }}"
                      formControlName="REGISTRATIONNO"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Fleet No</mat-label>
                    <input
                      formControlName="FLEETNO"
                      matInput
                      value="{{ vehicle_info.FLEETNO }}"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>
                <!--
                      <div class="col-xs-12 col-md-6">
                        <mat-slide-toggle color="primary" class="myswitch"
                          ><p>Litre Per Hour</p></mat-slide-toggle
                        >
                      </div> -->

                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Vehicle Type</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.VehType }}"
                      formControlName="VehType"
                      placeholder="Ex. ABCD"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Make Id</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.MAKEID }}"
                      [readonly]="true"
                      formControlName="MAKEID"
                      placeholder="Ex. ABCD"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Range Id</mat-label>
                    <input
                      matInput
                      formControlName="RANGEID"
                      [readonly]="true"
                      placeholder="Ex. ABCD"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Model Id</mat-label>
                    <input
                      matInput
                      [readonly]="true"
                      formControlName="MODELID"
                      placeholder="Ex. ABCD"
                    />
                  </mat-form-field>
                </div>

                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Fuel Type Id</mat-label>
                    <input
                      matInput
                      formControlName="FUELTYPEID"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Vehicle Industry Type</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.INDUSTRYTYPE }}"
                      formControlName="INDUSTRYTYPE"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Year</mat-label>
                    <input
                      formControlName="YEAR"
                      matInput
                      value="{{ vehicle_info.YEAR }}"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Tank Capacity</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.TANKCAPACITY }}"
                      formControlName="TANKCAPACITY"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>CO2 Emissions</mat-label>
                    <input matInput value="" placeholder="Ex. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>CO2 Emissions Class</mat-label>
                    <input matInput value="" placeholder="Ex. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Industry Norm</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.INDUSTRYNORM }}"
                      formControlName="INDUSTRYNORM"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Month Litres</mat-label>
                    <input
                      matInput
                      value=""
                      placeholder="Ex. 1234"
                      formControlName="MONTHLITRES"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Card No</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.CardNo }}"
                      formControlName="CardNo"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Customer Id</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.CUSTOMERID }}"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Customer Name</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.CUSTOMERNAME }}"
                      placeholder="Ex. ABCD"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>AS400 Company Id</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.AS400CompanyId }}"
                      placeholder="Ex. 1234"
                    />
                  </mat-form-field>
                </div>

                <div class="col-xs-12 col-md-12">
                  <mat-form-field>
                    <mat-label>Photo Path</mat-label>
                    <input
                      matInput
                      value="{{ vehicle_info.PHOTOPATH }}"
                      formControlName="PHOTOPATH"
                      placeholder="Ex. /path/"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <!-- <mat-slide-toggle
                  value="false"
                  color="primary"
                  class="myswitch"
                  formControlName="AS400"
                  ><p>AS400 Vehicle</p></mat-slide-toggle
                > -->
                  <div class="card055">
                    <p class="title">AS400 Vehicle</p>
                    <div class="butons">
                      <button
                        mat-stroked-button
                        (click)="selectAS400Vehicle('Yes')"
                        [ngClass]="isAS400Vehicle === 'Yes' ? 'active' : ''"
                        type="button"
                      >
                        <p>Yes</p>
                      </button>
                      <button
                        mat-stroked-button
                        (click)="selectAS400Vehicle('No')"
                        [ngClass]="isAS400Vehicle === 'No' ? 'active' : ''"
                        type="button"
                      >
                        <p>No</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-6">
                  <!-- <mat-slide-toggle color="primary" class="myswitch"
                  ><p>Extended Weekened</p></mat-slide-toggle
                > -->
                  <div class="card055">
                    <p class="title">Extended Weekened</p>
                    <div class="butons">
                      <button
                        mat-stroked-button
                        (click)="selectExtendedWeekened('Yes')"
                        [ngClass]="isExtendedWeekened === 'Yes' ? 'active' : ''"
                        type="button"
                      >
                        <p>Yes</p>
                      </button>
                      <button
                        mat-stroked-button
                        (click)="selectExtendedWeekened('No')"
                        [ngClass]="isExtendedWeekened === 'No' ? 'active' : ''"
                        type="button"
                      >
                        <p>No</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-6">
                  <!-- <mat-slide-toggle
                  color="primary"
                  class="myswitch"
                  value="false"
                  formControlName="ACTIVEMANAGE"
                  ><p>Manage this vehicle</p></mat-slide-toggle
                > -->
                  <div class="card055">
                    <p class="title">Manage This Vehicle</p>
                    <div class="butons">
                      <button
                        mat-stroked-button
                        (click)="selectManageThisVehicle('Yes')"
                        [ngClass]="isManageThisVehicle === 'Yes' ? 'active' : '' "
                        type="button"
                      >
                        <p>Yes</p>
                      </button>
                      <button
                        mat-stroked-button
                        (click)="selectManageThisVehicle('No')"
                        [ngClass]=" isManageThisVehicle === 'No' ? 'active' : '' "
                        type="button"
                      >
                        <p>No</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-6">
                  <!-- <mat-slide-toggle color="primary" class="myswitch"
                  ><p>No Weekend</p></mat-slide-toggle
                > -->
                  <div class="card055">
                    <p class="title">No Weekend</p>
                    <div class="butons">
                      <button
                        mat-stroked-button
                        (click)="selectNoWeekend('Yes')"
                        [ngClass]="isNoWeekend === 'Yes' ? 'active' : ''"
                        type="button"
                      >
                        <p>Yes</p>
                      </button>
                      <button
                        mat-stroked-button
                        (click)="selectNoWeekend('No')"
                        [ngClass]="isNoWeekend === 'No' ? 'active' : ''"
                        type="button"
                      >
                        <p>No</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12">
                  <!-- <div class="myswitches">
                  <mat-slide-toggle value="false" color="primary" class="myswitch"
                  formControlName="AS400"
                    ><p>Use AS400 Drivers</p></mat-slide-toggle
                  >
                </div> -->
                  <div class="card055">
                    <p class="title">Use AS400 Drivers</p>
                    <div class="butons">
                      <button
                        mat-stroked-button
                        (click)="selectUseAS400Drivers('Yes')"
                        [ngClass]="
                          isUseAS400Drivers === 'Yes' ? 'active' : ''
                        "
                        type="button"
                      >
                        <p>Yes</p>
                      </button>
                      <button
                        mat-stroked-button
                        (click)="selectUseAS400Drivers('No')"
                        [ngClass]="isUseAS400Drivers === 'No' ? 'active' : ''"
                        type="button"
                      >
                        <p>No</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-4">
                <div class="card010101">
                  <div
                    class="skeletons anime-skeleton"
                    *ngIf="
                      fetch_vehicle_response === 'loading' ||
                      fetch_vehicle_response === 'no_internet'
                    "
                  >
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                  </div>
                  <div
                    class="mytable"
                    *ngIf="fetch_vehicle_response === 'success'"
                  >
                    <!-- Cost Center  -->
                    <table
                      mat-table
                      [dataSource]="dataSource_CostCentre"
                      class="mat-elevation-z8"
                    >
                      <ng-container matColumnDef="COSTCENTRENAME">
                        <th mat-header-cell *matHeaderCellDef>
                          Cost Centre Name
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.COSTCENTRENAME }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="ActiveDate">
                        <th mat-header-cell *matHeaderCellDef>Active Date</th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.ActiveDate
                              ? row.ActiveDate.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="InactiveDate">
                        <th mat-header-cell *matHeaderCellDef>
                          In Active Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.InactiveDate
                              ? row.InactiveDate.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="
                          displayedColumns_CostCentre;
                          sticky: true
                        "
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns_CostCentre
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card010101">
                  <div
                    class="skeletons anime-skeleton"
                    *ngIf="
                      fetch_vehicle_response === 'loading' ||
                      fetch_vehicle_response === 'no_internet'
                    "
                  >
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                  </div>
                  <div
                    class="mytable"
                    *ngIf="fetch_vehicle_response === 'success'"
                  >
                    <!-- Account  -->
                    <table
                      mat-table
                      [dataSource]="dataSource_Account"
                      class="mat-elevation-z8"
                    >
                      <ng-container matColumnDef="ACCOUNTNAME">
                        <th mat-header-cell *matHeaderCellDef>
                          Account Name
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.ACCOUNTNAME }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="ACTIVEDATE">
                        <th mat-header-cell *matHeaderCellDef>Active Date</th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.ACTIVEDATE
                              ? row.ACTIVEDATE.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="INACTIVEDATE">
                        <th mat-header-cell *matHeaderCellDef>
                          In Active Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.INACTIVEDATE
                              ? row.INACTIVEDATE.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="
                          displayedColumns_Account;
                          sticky: true
                        "
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns_Account
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card010101">
                  <div
                    class="skeletons anime-skeleton"
                    *ngIf="
                      fetch_vehicle_response === 'loading' ||
                      fetch_vehicle_response === 'no_internet'
                    "
                  >
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                  </div>
                  <div
                    class="mytable"
                    *ngIf="fetch_vehicle_response === 'success'"
                  >
                    <!-- Driver  -->
                    <table
                      mat-table
                      [dataSource]="dataSource_Driver"
                      class="mat-elevation-z8"
                    >
                      <ng-container matColumnDef="DriverName">
                        <th mat-header-cell *matHeaderCellDef>Driver Name</th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.DriverName }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="ActiveDate">
                        <th mat-header-cell *matHeaderCellDef>Active Date</th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.ActiveDate
                              ? row.ActiveDate.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="InActiveDate">
                        <th mat-header-cell *matHeaderCellDef>
                          In Active Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.InActiveDate
                              ? row.InActiveDate.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="
                          displayedColumns_Driver;
                          sticky: true
                        "
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns_Driver"
                      ></tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card010101">
                  <div
                    class="skeletons anime-skeleton"
                    *ngIf="
                      fetch_vehicle_response === 'loading' ||
                      fetch_vehicle_response === 'no_internet'
                    "
                  >
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                  </div>
                  <div
                    class="mytable"
                    *ngIf="fetch_vehicle_response === 'success'"
                  >
                    <!-- Division -->
                    <table
                      mat-table
                      [dataSource]="dataSource_Division"
                      class="mat-elevation-z8"
                    >
                      <ng-container matColumnDef="DivisionName">
                        <th mat-header-cell *matHeaderCellDef>
                          Division Name
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.DivisionName }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="ActiveDate">
                        <th mat-header-cell *matHeaderCellDef>Active Date</th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.ActiveDate
                              ? row.ActiveDate.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="InActiveDate">
                        <th mat-header-cell *matHeaderCellDef>
                          In Active Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.InActiveDate
                              ? row.InActiveDate.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="
                          displayedColumns_Division;
                          sticky: true
                        "
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns_Division
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card010101">
                  <div
                    class="skeletons anime-skeleton"
                    *ngIf="
                      fetch_vehicle_response === 'loading' ||
                      fetch_vehicle_response === 'no_internet'
                    "
                  >
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                  </div>
                  <div
                    class="mytable"
                    *ngIf="fetch_vehicle_response === 'success'"
                  >
                    <!-- Active Status  -->
                    <table
                      mat-table
                      [dataSource]="dataSource_ActiveStatus"
                      class="mat-elevation-z8"
                    >
                      <ng-container matColumnDef="ACTIVE">
                        <th mat-header-cell *matHeaderCellDef>
                          Active Status
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.ACTIVE }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="ACTIVEDATE">
                        <th mat-header-cell *matHeaderCellDef>Active Date</th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.ACTIVEDATE
                              ? row.ACTIVEDATE.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="INACTIVEDATE">
                        <th mat-header-cell *matHeaderCellDef>
                          In Active Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.INACTIVEDATE
                              ? row.INACTIVEDATE.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="
                          displayedColumns_ActiveStatus;
                          sticky: true
                        "
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns_ActiveStatus
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card010101">
                  <div
                    class="skeletons anime-skeleton"
                    *ngIf="
                      fetch_vehicle_response === 'loading' ||
                      fetch_vehicle_response === 'no_internet'
                    "
                  >
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                  </div>
                  <div
                    class="mytable"
                    *ngIf="fetch_vehicle_response === 'success'"
                  >
                    <!-- Initial Odo  -->
                    <table
                      mat-table
                      [dataSource]="dataSource_InitialOdo"
                      class="mat-elevation-z8"
                    >
                      <ng-container matColumnDef="INITIALODO">
                        <th mat-header-cell *matHeaderCellDef>Inital Odo</th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.INITIALODO }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="ActiveDate">
                        <th mat-header-cell *matHeaderCellDef>Active Date</th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.ActiveDate
                              ? row.ActiveDate.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="InactiveDate">
                        <th mat-header-cell *matHeaderCellDef>
                          In Active Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.InactiveDate
                              ? row.InactiveDate.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="
                          displayedColumns_InitialOdo;
                          sticky: true
                        "
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns_InitialOdo
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card010101">
                  <div
                    class="skeletons anime-skeleton"
                    *ngIf="
                      fetch_vehicle_response === 'loading' ||
                      fetch_vehicle_response === 'no_internet'
                    "
                  >
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                  </div>
                  <div
                    class="mytable"
                    *ngIf="fetch_vehicle_response === 'success'"
                  >
                    <!-- Norm  -->
                    <table
                      mat-table
                      [dataSource]="dataSource_Norm"
                      class="mat-elevation-z8"
                    >
                      <ng-container matColumnDef="NORM">
                        <th mat-header-cell *matHeaderCellDef>Norm</th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.NORM }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="ActiveDate">
                        <th mat-header-cell *matHeaderCellDef>Active Date</th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.ActiveDate
                              ? row.ActiveDate.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="InActiveDate">
                        <th mat-header-cell *matHeaderCellDef>
                          In Active Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{
                            row.InActiveDate
                              ? row.InActiveDate.substring(0, 10)
                              : ""
                          }}
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumns_Norm; sticky: true"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns_Norm"
                      ></tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card010101">
                  <div
                    class="skeletons anime-skeleton"
                    *ngIf="
                      fetch_vehicle_response === 'loading' ||
                      fetch_vehicle_response === 'no_internet'
                    "
                  >
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                  </div>
                  <div
                    class="mytable"
                    *ngIf="fetch_vehicle_response === 'success'"
                  >
                    <!-- Initial Email Address  -->
                    <table
                      mat-table
                      [dataSource]="dataSource_EmailAddress"
                      class="mat-elevation-z8"
                    >
                      <ng-container matColumnDef="EMAILADDRESS">
                        <th mat-header-cell *matHeaderCellDef>
                          Email Address
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.EMAILADDRESS }}
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="
                          displayedColumns_EmailAddress;
                          sticky: true
                        "
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns_EmailAddress
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card010101">
                  <div
                    class="skeletons anime-skeleton"
                    *ngIf="
                      fetch_vehicle_response === 'loading' ||
                      fetch_vehicle_response === 'no_internet'
                    "
                  >
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                    <div class="skeleton"></div>
                  </div>
                  <div
                    class="mytable"
                    *ngIf="fetch_vehicle_response === 'success'"
                  >
                    <!-- Initial Month  -->
                    <table
                      mat-table
                      [dataSource]="dataSource_Month"
                      class="mat-elevation-z8"
                    >
                      <ng-container matColumnDef="Month">
                        <th mat-header-cell *matHeaderCellDef>Month</th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.Month }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="Year">
                        <th mat-header-cell *matHeaderCellDef>Year</th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.Year }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="Reason">
                        <th mat-header-cell *matHeaderCellDef>Reason</th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.Reason }}
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="
                          displayedColumns_Month;
                          sticky: true
                        "
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns_Month"
                      ></tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12" align="end">
          <button class="mybtn" mat-flat-button color="primary" type="submit">
            Save
          </button>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="tab==='2'">
      <div class="row p-4 ">
        <div class="col-4">
          <div class="card01010">
            <div class="row">
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Vehicle Id</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.VEHICLEID }}"
                    formControlName=""
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Company Id</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.COMPANYID }}"
                    [readonly]="true"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>AS400 Vehicle Id</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.AS400VEHICLEID }}"
                    formControlName="AS400VEHICLEID"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>

              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>MVA Number</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.MVA_Number }}"
                    formControlName="MVA_Number"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Registration No</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.REGISTRATIONNO }}"
                    formControlName="REGISTRATIONNO"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Fleet No</mat-label>
                  <input
                    formControlName="FLEETNO"
                    matInput
                    value="{{ vehicle_info.FLEETNO }}"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>
              <!--
                    <div class="col-xs-12 col-md-6">
                      <mat-slide-toggle color="primary" class="myswitch"
                        ><p>Litre Per Hour</p></mat-slide-toggle
                      >
                    </div> -->

              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Vehicle Type</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.VehType }}"
                    formControlName="VehType"
                    placeholder="Ex. ABCD"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Make Id</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.MAKEID }}"
                    [readonly]="true"
                    formControlName="MAKEID"
                    placeholder="Ex. ABCD"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Range Id</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.RANGEID }}"
                    [readonly]="true"
                    placeholder="Ex. ABCD"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Model Id</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.MODELID }}"
                    [readonly]="true"
                    formControlName="MODELID"
                    placeholder="Ex. ABCD"
                  />
                </mat-form-field>
              </div>

              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Fuel Type Id</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.FUELTYPEID }}"
                    formControlName="FUELTYPEID"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Vehicle Industry Type</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.INDUSTRYTYPE }}"
                    formControlName="INDUSTRYTYPE"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Year</mat-label>
                  <input
                    formControlName="YEAR"
                    matInput
                    value="{{ vehicle_info.YEAR }}"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Tank Capacity</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.TANKCAPACITY }}"
                    formControlName="TANKCAPACITY"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>CO2 Emissions</mat-label>
                  <input matInput value="" placeholder="Ex. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>CO2 Emissions Class</mat-label>
                  <input matInput value="" placeholder="Ex. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Industry Norm</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.INDUSTRYNORM }}"
                    formControlName="INDUSTRYNORM"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Month Litres</mat-label>
                  <input
                    matInput
                    value=""
                    placeholder="Ex. 1234"
                    formControlName="MONTHLITRES"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Card No</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.CardNo }}"
                    formControlName="CardNo"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Customer Id</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.CUSTOMERID }}"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Customer Name</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.CUSTOMERNAME }}"
                    placeholder="Ex. ABCD"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>AS400 Company Id</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.AS400CompanyId }}"
                    placeholder="Ex. 1234"
                  />
                </mat-form-field>
              </div>

              <div class="col-xs-12 col-md-12">
                <mat-form-field>
                  <mat-label>Photo Path</mat-label>
                  <input
                    matInput
                    value="{{ vehicle_info.PHOTOPATH }}"
                    formControlName="PHOTOPATH"
                    placeholder="Ex. /path/"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <!-- <mat-slide-toggle
                value="false"
                color="primary"
                class="myswitch"
                formControlName="AS400"
                ><p>AS400 Vehicle</p></mat-slide-toggle
              > -->
                <div class="card055">
                  <p class="title">AS400 Vehicle</p>
                  <div class="butons">
                    <button
                      mat-stroked-button
                      (click)="selectAS400Vehicle('Yes')"
                      [ngClass]="isAS400Vehicle === 'Yes' ? 'active' : ''"
                      type="button"
                    >
                      <p>Yes</p>
                    </button>
                    <button
                      mat-stroked-button
                      (click)="selectAS400Vehicle('No')"
                      [ngClass]="isAS400Vehicle === 'No' ? 'active' : ''"
                      type="button"
                    >
                      <p>No</p>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <!-- <mat-slide-toggle color="primary" class="myswitch"
                ><p>Extended Weekened</p></mat-slide-toggle
              > -->
                <div class="card055">
                  <p class="title">Extended Weekened</p>
                  <div class="butons">
                    <button
                      mat-stroked-button
                      (click)="selectExtendedWeekened('Yes')"
                      [ngClass]="
                        isExtendedWeekened === 'Yes' ? 'active' : ''
                      "
                      type="button"
                    >
                      <p>Yes</p>
                    </button>
                    <button
                      mat-stroked-button
                      (click)="selectExtendedWeekened('No')"
                      [ngClass]="
                        isExtendedWeekened === 'No' ? 'active' : ''
                      "
                      type="button"
                    >
                      <p>No</p>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <!-- <mat-slide-toggle
                color="primary"
                class="myswitch"
                value="false"
                formControlName="ACTIVEMANAGE"
                ><p>Manage this vehicle</p></mat-slide-toggle
              > -->
                <div class="card055">
                  <p class="title">Manage This Vehicle</p>
                  <div class="butons">
                    <button
                      mat-stroked-button
                      (click)="selectManageThisVehicle('Yes')"
                      [ngClass]="
                        isManageThisVehicle === 'Yes' ? 'active' : ''
                      "
                      type="button"
                    >
                      <p>Yes</p>
                    </button>
                    <button
                      mat-stroked-button
                      (click)="selectManageThisVehicle('No')"
                      [ngClass]="
                        isManageThisVehicle === 'No' ? 'active' : ''
                      "
                      type="button"
                    >
                      <p>No</p>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <!-- <mat-slide-toggle color="primary" class="myswitch"
                ><p>No Weekend</p></mat-slide-toggle
              > -->
                <div class="card055">
                  <p class="title">No Weekend</p>
                  <div class="butons">
                    <button
                      mat-stroked-button
                      (click)="selectNoWeekend('Yes')"
                      [ngClass]="isNoWeekend === 'Yes' ? 'active' : ''"
                      type="button"
                    >
                      <p>Yes</p>
                    </button>
                    <button
                      mat-stroked-button
                      (click)="selectNoWeekend('No')"
                      [ngClass]="isNoWeekend === 'No' ? 'active' : ''"
                      type="button"
                    >
                      <p>No</p>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-12">
                <!-- <div class="myswitches">
                <mat-slide-toggle value="false" color="primary" class="myswitch"
                formControlName="AS400"
                  ><p>Use AS400 Drivers</p></mat-slide-toggle
                >
              </div> -->
                <div class="card055">
                  <p class="title">Use AS400 Drivers</p>
                  <div class="butons">
                    <button
                      mat-stroked-button
                      (click)="selectUseAS400Drivers('Yes')"
                      [ngClass]="
                        isUseAS400Drivers === 'Yes' ? 'active' : ''
                      "
                      type="button"
                    >
                      <p>Yes</p>
                    </button>
                    <button
                      mat-stroked-button
                      (click)="selectUseAS400Drivers('No')"
                      [ngClass]="isUseAS400Drivers === 'No' ? 'active' : ''"
                      type="button"
                    >
                      <p>No</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="row">
            <mat-form-field class="">
              <mat-label>Search Driver</mat-label>
              <input
                type="text"
                matInput
                [formControl]="myControl"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayFn"
              >
                <mat-option
                  class="row"
                  *ngFor="let driver of drivers"
                  [value]="driver"
                >
                  <div class="row">
                    <div class="col-md-3">{{ driver.DRIVERID }}</div>
                    <div class="col-md-7">{{ driver.DRIVERNAME }}</div>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="card010101">
              <div
                class="skeletons anime-skeleton"
                *ngIf="
                  fetch_vehicle_response === 'loading' ||
                  fetch_vehicle_response === 'no_internet'
                "
              >
                <div class="skeleton"></div>
                <div class="skeleton"></div>
                <div class="skeleton"></div>
                <div class="skeleton"></div>
                <div class="skeleton"></div>
              </div>
              <div
                class="mytable"
                *ngIf="fetch_vehicle_response === 'success'"
              >
                <!-- Driver  -->
                <table
                  mat-table
                  [dataSource]="dataSource_Driver"
                  class="mat-elevation-z8"
                >
                  <ng-container matColumnDef="DriverName">
                    <th mat-header-cell *matHeaderCellDef>Driver Name</th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.DriverName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="ActiveDate" >
                    <th mat-header-cell *matHeaderCellDef>Active Date</th>
                    <td mat-cell *matCellDef="let row">
                      {{
                        row.ActiveDate
                          ? row.ActiveDate.substring(0, 10)
                          : ""
                      }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="InActiveDate" >
                    <th mat-header-cell *matHeaderCellDef>
                      In Active Date
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{
                        row.InActiveDate
                          ? row.InActiveDate.substring(0, 10)
                          : ""
                      }}
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns_Driver; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns_Driver"
                  ></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>



  </div>
</div>
