import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyService } from '../companies/company.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FuelStationService {
  constructor(private http: HttpClient, private _companyServ: CompanyService) {}

  private service_base = "https://zedadev.apigw-aw-eu.webmethods.io/gateway/IFM/1.0/"

  private headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });
  getAllFuelStations(): Observable<any> {
    let url = '/restv2/getAllFuelStations';
    return this.http.get<any[]>(url);
  }

  getCompanyFuelStations(id: string): Observable<any> {
    let url =
    this.service_base+'getFuelStationByID?COMPANYID=' +
      id +
      '&Min=0' +
      '&Max=300';
    return this.http.get<any[]>(url, {
      headers: this.headers,
    });
  }

  updateFuelStation(data: any): Observable<any> {
    let url = this.service_base+'updateFuelStation';
    return this.http.post<any>(
      url,
      {
        DriverInfo: data,
      },
      {
        headers: this.headers,
      }
    );
  }

  deleteFuelStation(id: string): Observable<any> {
    let url = this.service_base+'deleteFuelStation' + id;
    return this.http.delete<any[]>(url, {
      headers: this.headers,
    });
  }

  getFuelStationKPI(): Observable<any> {
    let url = '/restv2/getFuelStationKPI';
    return this.http.get<any[]>(url, {
      headers: this.headers,
    });
  }

}
