import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.css'],
})
export class AddVehicleComponent {
  constructor(
    private dialogRef: MatDialogRef<AddVehicleComponent>,
    private _snackBar: SnackbarsService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  addCompanyForm = this.formBuilder.group({
    COMPANYID: ['', Validators.required],
    COMPANYNAME: ['', Validators.required],
    CUSTOMERID: ['', Validators.required],
    CUSTOMERNAME: ['', Validators.required],
    AS400CompanyId: ['', Validators.required],
    AS400CustomerId: ['', Validators.required],
    AS400VEHICLEID: ['', Validators.required],
    AS400: ['', Validators.required],
    MVA_Number: ['', Validators.required],
    REGISTRATIONNO: ['', Validators.required],
    FLEETNO: ['', Validators.required],
    LITREPERHOUR: ['', Validators.required],
    CardId: ['', Validators.required],
    CardNo: ['', Validators.required],
    MAKEID: ['', Validators.required],
    VEHICLEMAKE: ['', Validators.required],
    RANGEID: ['', Validators.required],
    VEHICLERANGE: ['', Validators.required],
    MODELID: ['', Validators.required],
    VEHICLEMODEL: ['', Validators.required],
    VEHICLETYPEID: ['', Validators.required],
    FUELTYPEID: ['', Validators.required],
    FUELTYPEDESCRIPTION: ['', Validators.required],
    VEHICLEINDUSTRYID: ['', Validators.required],
    INDUSTRYTYPE: ['', Validators.required],
    YEAR: ['', Validators.required],
    INDUSTRYNORM: ['', Validators.required],
    MONTHLITRES: ['', Validators.required],
    PHOTOPATH: ['', Validators.required],
    ACTIVEMANAGE: ['', Validators.required],
    TANKCAPACITY: ['', Validators.required],
    FirstDate: ['', Validators.required],
    VehType: ['', Validators.required],
    EXTENDEDWEEKEND: ['', Validators.required],
    NOWEEKEND: ['', Validators.required],
    CO2_EMMISSIONS: ['', Validators.required],
    CO2_EMMISSIONS_CLASS: ['', Validators.required],
  });
  closeModal(): void {
    this.dialogRef.close();
  }
  onSubmit() {}
}
