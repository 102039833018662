import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, map, startWith } from 'rxjs';
import { User } from 'src/app/models/user';
import { CompanyService } from 'src/app/services/companies/company.service';
import { DriverService } from 'src/app/services/drivers/driver.service';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
import { VehicleService } from 'src/app/services/vehicles/vehicle.service';

@Component({
  selector: 'app-update-vehicle',
  templateUrl: './update-vehicle.component.html',
  styleUrls: ['./update-vehicle.component.css'],
})
export class UpdateVehicleComponent {
  drivers: any[] = [

  ];
  constructor(
    private dialogRef: MatDialogRef<UpdateVehicleComponent>,
    private _snackBar: SnackbarsService,
    private formBuilder: FormBuilder,
    private _vehicleServ: VehicleService,
    private _driverServ: DriverService,
    private _companyServe: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  tab = '1';

  selectTab(value: any) {
    this.tab = value;
  }

  rowToggle = 1;
  assigned_driver: any = {};



  filteredOptions: Observable<any[]> | undefined;
  myControl = new FormControl<string | any>('');
  vehicle_info: any = this.data;
  updateVehicleForm = this.formBuilder.group({
    AS400VEHICLEID: [this.vehicle_info.VEHICLEID, Validators.required],
    AS400: [this.vehicle_info.AS400,Validators.required],
    MVA_Number: [this.vehicle_info.MVA_Number, Validators.required],
    REGISTRATIONNO: [this.vehicle_info.REGISTRATIONNO, Validators.required],
    FLEETNO: [this.vehicle_info.FLEETNO, Validators.required],
    LITREPERHOUR: [this.vehicle_info.LITREPERHOUR, Validators.required],
    CardNo: [this.vehicle_info.CardNo, Validators.required],
    MAKEID: [this.vehicle_info.MAKEID, Validators.required],
    VEHICLEMAKE: [this.vehicle_info.VEHICLEMAKE, Validators.required],
    RANGEID: [this.vehicle_info.RANGEID, Validators.required],
    VEHICLERANGE: [this.vehicle_info.VEHICLERANGE, Validators.required],
    MODELID: [this.vehicle_info.MODELID, Validators.required],
    VEHICLEMODEL: [this.vehicle_info.VEHICLEMODEL, Validators.required],
    VEHICLETYPEID: [this.vehicle_info.VEHICLETYPEID, Validators.required],
    FUELTYPEID: [this.vehicle_info.FUELTYPEID, Validators.required],
    FUELTYPEDESCRIPTION: [
      this.vehicle_info.FUELTYPEDESCRIPTION,
      Validators.required,
    ],
    VEHICLEINDUSTRYID: [
      this.vehicle_info.VEHICLEINDUSTRYID,
      Validators.required,
    ],
    INDUSTRYTYPE: [this.vehicle_info.INDUSTRYTYPE, Validators.required],
    YEAR: [this.vehicle_info.YEAR, Validators.required],
    INDUSTRYNORM: [this.vehicle_info.INDUSTRYNORM, Validators.required],
    MONTHLITRES: [this.vehicle_info.MONTHLITRES, Validators.required],
    PHOTOPATH: [this.vehicle_info.PHOTOPATH, Validators.required],
    ACTIVEMANAGE: [this.vehicle_info.AC, Validators.required],
    TANKCAPACITY: [this.vehicle_info.ACTIVEMANAGE, Validators.required],
    FirstDate: [this.vehicle_info.FirstDate, Validators.required],
    VehType: [this.vehicle_info.VehType, Validators.required],
    VEHICLEID: [this.vehicle_info.VEHICLEID, Validators.required],
  });
  closeModal(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    this._vehicleServ.updateVehicle(this.updateVehicleForm.value).subscribe(
      (response: any) => {
        this.dialogRef.close('update');
        this._snackBar.success('Vehicle updated!');
      },
      (error) => {
        this._snackBar.error(error.message);
      }
    );
  }

  // ---------------------------

  // Table Cost Centre
  displayedColumns_CostCentre: string[] = [
    'COSTCENTRENAME',
    'ActiveDate',
    'InactiveDate',
  ];
  dataSource_CostCentre: any = [];

  // Table Account
  displayedColumns_Account: string[] = [
    'ACCOUNTNAME',
    'ACTIVEDATE',
    'INACTIVEDATE',
  ];
  dataSource_Account: any = [];

  // Table Driver
  displayedColumns_Driver: string[] = [
    'DriverName',
    'ActiveDate',
    'InActiveDate',
  ];
  dataSource_Driver: any = [];

  // Table Division
  displayedColumns_Division: string[] = [
    'DivisionName',
    'ActiveDate',
    'InActiveDate',
  ];
  dataSource_Division: any = [];

  // Table Active Status
  displayedColumns_ActiveStatus: string[] = [
    'ACTIVE',
    'ACTIVEDATE',
    'INACTIVEDATE',
  ];
  dataSource_ActiveStatus: any = [];

  // Table Norm
  displayedColumns_InitialOdo: string[] = [
    'INITIALODO',
    'ActiveDate',
    'InactiveDate',
  ];
  dataSource_InitialOdo: any = [];

  // Table Norm
  displayedColumns_Norm: string[] = ['NORM', 'ActiveDate', 'InActiveDate'];
  dataSource_Norm: any = [];

  // Table Norm
  displayedColumns_EmailAddress: string[] = ['EMAILADDRESS'];
  dataSource_EmailAddress: any = [];

  // Table Month
  displayedColumns_Month: string[] = ['Month', 'Year', 'Reason'];
  dataSource_Month: any = [];

  isAS400Vehicle = '';
  isExtendedWeekened = '';
  isManageThisVehicle = '';
  isNoWeekend = '';
  isUseAS400Drivers = '';
  selectAS400Vehicle(value: any) {
    if (this.isAS400Vehicle === value) {
      this.isAS400Vehicle = '';
    } else {
      this.isAS400Vehicle = value;
    }
  }
  selectExtendedWeekened(value: any) {
    if (this.isExtendedWeekened === value) {
      this.isExtendedWeekened = '';
    } else {
      this.isExtendedWeekened = value;
    }
  }
  selectManageThisVehicle(value: any) {
    if (this.isManageThisVehicle === value) {
      this.isManageThisVehicle = '';
    } else {
      this.isManageThisVehicle = value;
    }
  }
  selectNoWeekend(value: any) {
    if (this.isNoWeekend === value) {
      this.isNoWeekend = '';
    } else {
      this.isNoWeekend = value;
    }
  }
  selectUseAS400Drivers(value: any) {
    if (this.isUseAS400Drivers === value) {
      this.isUseAS400Drivers = '';
    } else {
      this.isUseAS400Drivers = value;
    }
  }
  vehicle_info_reponse = '';

  FetchVehicleInfo() {
    this.vehicle_info_reponse = 'loading';
    this._vehicleServ
      .getVehicleByRegistration(this.data.REGISTRATIONNO)
      .subscribe({
        next: (data: any) => {
          if (data.VehicleDetails && data.VehicleDetails.length > 0) {
            this.vehicle_info_reponse = 'success';
            this.vehicle_info = data.VehicleDetails[0];
            // this.selectedVehData
          } else {
            this.vehicle_info_reponse = 'no_results';
            this._snackBar.error('Vehicle not found');
          }
        },
        error: (error: any) => {
          this.vehicle_info_reponse = 'network_err';
          this._snackBar.error('Check your internet connectivity');
        },
      });
  }

  fetch_vehicle_response = 'loading';

  FetchVehicleDetail() {
    this.fetch_vehicle_response = 'loading';
    this._vehicleServ.getVehicleDetails(this.data.REGISTRATIONNO).subscribe({
      next: (response: any) => {
        if (response.VehicleDetails) {
          this.dataSource_CostCentre = response.VehicleDetails.CostCenter;
          this.dataSource_Account = response.VehicleDetails.Account;
          this.dataSource_Driver = response.VehicleDetails.Driver;
          this.dataSource_Division = response.VehicleDetails.Division;
          this.dataSource_ActiveStatus = response.VehicleDetails.ActiveStatus;
          this.dataSource_InitialOdo = response.VehicleDetails.IntialODO;
          this.dataSource_Norm = response.VehicleDetails.VehNorm;

          this.dataSource_EmailAddress = response.VehicleDetails.Email;
          this.dataSource_Month = response.VehicleDetails.NoCal;
          this.fetch_vehicle_response = 'success';
        } else {
          this.fetch_vehicle_response = 'no_results';
          this._snackBar.error('No results found');
        }
      },
      error: (error: any) => {
        this.fetch_vehicle_response = 'no_internet';
        this._snackBar.error('Check your internet connectivity');
      },
    });
  }

  ngOnInit() {
    if (this.data) {
      this.isAS400Vehicle =
        this.data.AS400 && this.data.AS400.toString() === '1' ? 'Yes' : '';
      this.isManageThisVehicle =
        this.data.ACTIVEMANAGE && this.data.ACTIVEMANAGE.toString() === '1'
          ? 'Yes'
          : '';
    }
    this.FetchVehicleInfo();
    this.FetchVehicleDetail();
    this.fetchDrivers();
  }
  toggleRow(id?: any): void {
    if (id) {
      this.rowToggle = id;
    } else {
      this.rowToggle = 1;
      document.getElementById('vehicle-toggle')?.click();
    }
  }

  fetchDrivers() {
    let company_id = this._companyServe.getCompanyId();
    if (company_id)
      this._driverServ
        .getDriversByCompanyId(company_id)
        .subscribe((response: any) => {
          console.log(response);
          this.drivers = response.results;
        });
  }

  displayFn(driver: any): string {
    return driver && driver.DRIVERNAME ? driver.DRIVERNAME : '';
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.drivers.filter((option: { DRIVERNAME: string }) =>
      option.DRIVERNAME.toLowerCase().includes(filterValue)
    );
  }

  updateDriverVehicle() {
    try {
    } catch (error) {}
  }

}


