<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="lft">
        <ng-container *ngIf="data.action == 'create'">
          <p>Create New Company</p>
          <p>Add new company</p>
        </ng-container>
        <ng-container *ngIf="data.action == 'update'">
          <p>Update Company</p>
          <p>{{data.form.COMPANYNAME}}</p>
        </ng-container>
      </div>
      <div class="rgt">
        <button mat-flat-button (click)="closeModal()">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
  
    <div class="modal-contain">
      <form class="myform">
        <div class="row">
          <div class="col-8">
            <div class="htitle">
              <p class="FxLine">Company Information</p>
            </div>
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <mat-form-field>
                  <mat-label>Company Name</mat-label>
                  <input matInput name="COMPANYNAME" [value]="data.form.COMPANYNAME" (keyup)="onChangeValue('COMPANYNAME', $event.target.value)"  placeholder="Ex. Company Name" maxlength="50"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Contact Person (Optional)</mat-label>
                  <input matInput name="ContactPerson" [value]="data.form.ContactPerson" (keyup)="onChangeValue('ContactPerson', $event.target.value)" placeholder="Ex. Name Surname" maxlength="50"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Telephone (Optional)</mat-label>
                  <input matInput name="Telephone" [value]="data.form.Telephone" (keyup)="onChangeValue('Telephone', $event.target.value)" placeholder="Ex. 0124567890" maxlength="50"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Cell (Optional)</mat-label>
                  <input matInput name="Cell" [value]="data.form.Cell" (keyup)="onChangeValue('Cell', $event.target.value)" placeholder="Ex. 08124567890" maxlength="50"/>
                </mat-form-field>
              </div>
             
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Wesbank Id (Optional)</mat-label>
                  <input matInput name="AvisWesbankId" [value]="data.form.AvisWesbankId.replaceAll(' ','')" (keyup)="onChangeValue('AvisWesbankId', $event.target.value)" type="number" placeholder="Ex. 1234"/>
                </mat-form-field>
              </div>
  
              <div class="col-xs-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Currency Symbol</mat-label>
                  <mat-select name="CURRENCYSYMBOL" (selectionChange)="onChangeValue('CURRENCYSYMBOL', $event.value)" [value]="data.form.CURRENCYSYMBOL">
                    <mat-option value="R">R</mat-option>
                    <mat-option value="$">$</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Trailing</mat-label>
                  <mat-select name="TRAILING" (selectionChange)="onChangeValue('TRAILING', $event.value)" [value]="data.form.TRAILING.toString()">
                    <mat-option value="0">Yes</mat-option>
                    <mat-option value="1">No</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
  
              <!-- <div class="col-xs-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>From Town</mat-label>
                  <mat-select name="FROMTOWNNAME">
                    <ng-container *ngIf="getFromTownResponse==='loading'">
                        <mat-option value="">Loading....</mat-option>
                    </ng-container>
                    <ng-container *ngIf="getFromTownResponse==='success'">
                      <mat-option *ngFor="let r of getFromTownData" value="town">{{ r.TOWNNAME }}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div> -->
              <!-- <div class="col-xs-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>To Town</mat-label>
                  <mat-select name="TOTOWNNAME">
                    <ng-container *ngIf="getToTownResponse==='loading'">
                        <mat-option value="">Loading....</mat-option>
                    </ng-container>
                    <ng-container *ngIf="getToTownResponse==='success'">
                      <mat-option *ngFor="let r of getToTownData" value="town">{{ r.TOWNNAME }}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div> -->
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Fuel Measure ID</mat-label>
                  <mat-select name="FUELMEASUREID" (selectionChange)="onChangeValue('FUELMEASUREID', $event.value)" [value]="data.form.FUELMEASUREID.toString()">
                    <mat-option value="1">Litres</mat-option>
                    <mat-option value="0">Gallons</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Perc Upper Limit</mat-label>
                  <input matInput type="number" name="PERCUPPERLIMIT" (keyup)="onChangeValue('PERCUPPERLIMIT', $event.target.value)" placeholder="Ex. 0.00" [value]="data.form.PERCUPPERLIMIT"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Perc Lower Limit</mat-label>
                  <input matInput type="number" name="PERCLOWERLIMIT" (keyup)="onChangeValue('PERCLOWERLIMIT', $event.target.value)" [value]="data.form.PERCLOWERLIMIT" placeholder="Ex. 0.00"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Tank Exceed</mat-label>
                  <input matInput type="number" name="TANKEXCEED" (keyup)="onChangeValue('TANKEXCEED', $event.target.value)" placeholder="Ex. 10%" [value]="data.form.TANKEXCEED"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Lper Hour Calculation</mat-label>
                  <input matInput type="number" name="LperHourCalc" (keyup)="onChangeValue('LperHourCalc', $event.target.value)" placeholder="Ex. 0.00" [value]="data.form.LperHourCalc"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Days To Edit</mat-label>
                  <input matInput type="number" name="DAYSTOEDIT" (keyup)="onChangeValue('DAYSTOEDIT', $event.target.value)" placeholder="Ex. 0" [value]="data.form.DAYSTOEDIT"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Perc No Loss</mat-label>
                  <input matInput type="number" type="text" name="PERCNOLOSS" (keyup)="onChangeValue('PERCNOLOSS', $event.target.value)" placeholder="Ex. 0.00" [value]="data.form.PERCNOLOSS"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>High Odo</mat-label>
                  <input matInput type="number" name="HighOdoValue" (keyup)="onChangeValue('HighOdoValue', $event.target.value)" placeholder="Ex. 0.00" [value]="data.form.HighOdoValue"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>TPS Export Path</mat-label>
                  <input matInput type="text" name="TPSexportPath" [value]="data.form.TPSexportPath" (keyup)="onChangeValue('TPSexportPath', $event.target.value)" placeholder="Ex. 1234" maxlength="100"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <button mat-stroked-button class="myradio" (click)="selectDrivers('INUSE', '1')" type="button" >
                  <div class="contain">
                    <mat-radio-button name="INUSE" [checked]="data.form.INUSE == '1'? true : false " color="primary">In-use</mat-radio-button>
                  </div>
                </button>
              </div>
              <div class="col-xs-12 col-md-4">
                <button mat-stroked-button class="myradio" (click)="selectDrivers('ASSIGNDRIVERSIMPORT', '1')" type="button">
                  <div class="contain">
                    <mat-radio-button name="ASSIGNDRIVERSIMPORT" [checked]="data.form.ASSIGNDRIVERSIMPORT == '1'? true : false " color="primary">Assign Drivers at import</mat-radio-button>
                  </div>
                </button>
              </div>
              <div class="col-xs-12 col-md-4">
                <button mat-stroked-button class="myradio" (click)="selectDrivers('UseAS400Drivers', '1')" type="button">
                  <div class="contain">
                    <mat-radio-button name="UseAS400Drivers" [checked]="data.form.UseAS400Drivers == '1'? true : false " color="primary">Use AS400 Drivers</mat-radio-button>
                  </div>
                </button>
              </div>
            </div>
            <mat-progress-bar mode="indeterminate"  *ngIf="addCompanyResponse === 'loading' || updateCompanyResponse === 'loading'"></mat-progress-bar>

            <button class="mybtn" [disabled]="addCompanyResponse === 'loading' || updateCompanyResponse === 'loading' ? true : false" *ngIf="data.action == 'create'" (click)="addCompany()" type="button" mat-flat-button color="primary">Add Company</button>
            <button class="mybtn" [disabled]="addCompanyResponse === 'loading' || updateCompanyResponse === 'loading' ? true : false" *ngIf="data.action == 'update'" (click)="updateCompany()" type="button" mat-flat-button color="primary">Update Company</button>
          </div>
          <div class="col-4">
            <div class="searchbox">
              <i class="fa-solid fa-magnifying-glass"></i>
              <input type="text" placeholder="Search customers here" (keyup)="handle_search($event.target)" [value]="search_string"/>
            </div>
            <div class="cardscontainer">
              <div class="card001" *ngFor="let r of CustomerList" >
                <div class="lft">
                  <i class="fa fa-building"></i>
                  <div class="titles">
                    <p>{{ r.CUSTOMERNAME }}</p>
                    <p>{{ r.AS400CUSTOMERID }}</p>
                  </div>
                </div>
                <div class="rgt">
                  <!-- <button mat-stroked-button type="button">
                    <p>View Customer</p>
                  </button> -->
                </div>
              </div>
              <ng-container *ngIf="CustomerList.length < 1 && getCompanyCustomersResponse !== 'loading'">
                <div class="myfeedback">
                  <div class="wrapp">
                      <i class="fa-solid fa-link"></i>
                      <p>No AS400 Customers linked to this company.</p>
                  </div>
              </div>
              </ng-container>
              <ng-container *ngIf="getCompanyCustomersResponse === 'loading'">
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
                <div class="card001-skeleton anime-skeleton">
                    <div class=""></div>
                    <p></p>
                </div>
              </ng-container>
  
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  