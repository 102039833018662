import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-add-fuel-station',
  templateUrl: './add-fuel-station.component.html',
  styleUrls: ['./add-fuel-station.component.css'],
})
export class AddFuelStationComponent {
  constructor(
    public dialogRef: MatDialogRef<AddFuelStationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeModal(data?: any): void {
    this.dialogRef.close({ event: data });
  }
}
