<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>Update Vehicle</p>
      <p>Company Name</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain">
    <form class="myform">
      <!-- <div class="htitle">
                <p class="FxLine">
                    Vehicle Information
                </p>
            </div> -->
      <div class="row">
        <div class="col-12 col-md-9">
          <div class="row">
            <div class="col-xs-12 col-md-4">
                <mat-form-field >
                    <mat-label>Vehicle Id</mat-label>
                    <input matInput value="" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-4">
                <mat-form-field >
                    <mat-label>Company Id</mat-label>
                    <input matInput value="" placeholder="Ex. 1234">
                </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-slide-toggle color="primary" class="myswitch"
                ><p>AS400 Vehicle</p></mat-slide-toggle
              >
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>AS400 Vehicle Id</mat-label>
                <input matInput value="" placeholder="Ex. 1234" />
              </mat-form-field>
            </div>

            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>MVA Number</mat-label>
                <input matInput value="" placeholder="Ex. 1234" />
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>Registration No</mat-label>
                <input matInput value="" placeholder="Ex. 1234" />
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>Fleet No</mat-label>
                <input matInput value="" placeholder="Ex. 1234" />
              </mat-form-field>
            </div>

            <div class="col-xs-12 col-md-4">
              <mat-slide-toggle color="primary" class="myswitch"
                ><p>Litre Per Hour</p></mat-slide-toggle
              >
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Vehicle Type</mat-label>
            <input matInput value="" placeholder="Ex. ABCD" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Make Id</mat-label>
            <input
              matInput
              value="JEEP"
              disabled="true"
              placeholder="Ex. ABCD"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Range Id</mat-label>
            <input
              matInput
              value="Grand Cheroke"
              disabled="true"
              placeholder="Ex. ABCD"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Model Id</mat-label>
            <input
              matInput
              value="4.0 Sports Auto"
              disabled="true"
              placeholder="Ex. ABCD"
            />
          </mat-form-field>
        </div>

        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Fuel Type Id</mat-label>
            <input matInput value="" placeholder="Ex. 1234" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Vehicle Industry Type</mat-label>
            <input matInput value="" placeholder="Ex. 1234" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Year</mat-label>
            <input matInput value="" placeholder="Ex. 1234" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Tank Capacity</mat-label>
            <input matInput value="" placeholder="Ex. 1234" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>CO2 Emissions</mat-label>
            <input matInput value="" placeholder="Ex. 1234" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>CO2 Emissions Class</mat-label>
            <input matInput value="" placeholder="Ex. 1234" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Industry Norm</mat-label>
            <input matInput value="" placeholder="Ex. 1234" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Month Rands</mat-label>
            <input matInput value="" placeholder="Ex. 1234" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Card No</mat-label>
            <input matInput value="" placeholder="Ex. 1234" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Customer Id</mat-label>
            <input matInput value="" placeholder="Ex. 1234" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Customer Name</mat-label>
            <input matInput value="" placeholder="Ex. ABCD" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>AS400 Company Id</mat-label>
            <input matInput value="" placeholder="Ex. 1234" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-slide-toggle color="primary" class="myswitch"
            ><p>Extended Weekened</p></mat-slide-toggle
          >
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-slide-toggle color="primary" class="myswitch"
            ><p>Manage this vehicle</p></mat-slide-toggle
          >
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-slide-toggle color="primary" class="myswitch"
            ><p>No Weekend</p></mat-slide-toggle
          >
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Photo Path</mat-label>
            <input matInput value="" placeholder="Ex. /path/" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
                    <div class="myswitches">

                        <mat-slide-toggle color="primary" class="myswitch"><p>Use AS400 Drivers</p></mat-slide-toggle>
                    </div>
                </div>

        <div class="col-xs-12">
          <button class="mybtn" mat-flat-button color="primary" type="submit">
            Add Company
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
