import { Component,Inject } from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import exporter from '@byetool/json-exporter'
@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent {
  
  constructor(
    public dialogRef: MatDialogRef<ExportComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,private _snackBar: MatSnackBar) {}
    
    closeModal(): void {
      this.dialogRef.close();
    }
    openSnackBar(message: string, action: string, type: string) {
      this._snackBar.open(message, action, {duration: 3000, panelClass: type});
    }




    dataSource = this.data.selected_transcations;
    export_method: string = "custom-selected"; //Default
    export_as: string = "excel"; //Default

    ExportMethod(d:any){
      this.export_method = d;
    }
    ExportAs(d:any){
      this.export_as = d;
    }

    
    ngOnInit(): void {
     
      if(this.dataSource.length<1){
        this.export_method = 'date-range'
      }
    }

    ExportIt(){
    // const data = [{ foo: 'foo'}, { bar: 'bar' }]
    // const fileName = 'download'
    // const exportType =  exportFromJSON.types.xls
    // exportFromJSON({ data, fileName, exportType })

 
    if(this.export_method=="custom-selected"){
      if(this.export_as=="excel"){
        const filename = 'XLSX_IFM_Transcations ('+this.data.selected_transcations.length+') - '+Math.floor(Math.random() * 1000000);
        exporter.toXLSX(this.data.selected_transcations, filename, {
          headers: ['REGISTRATIONNO', 'FLEETNO', 'DATETIME', 'TANKFULL','NEWODO', 'CURRENTODO', 'PREVIOUSODO', 'TRIPLITRES', 'CALCLITRES', 'CALCKM', 'CONSUMPTIONKML', 'CONSUMPTIONL100', 'LOSSAMOUNT', 'LOSSLITRES', 'PERCLOSS', 'ACCOUNTID', 'ACCOUNTNAME', 'AMOUNT', 'AS400COSTCENTREID', 'AS400CUSTOMERID', 'AS400ACCOUNTID', 'AS400CompanyId', 'CARDNO', 'CARDNOID', 'COMMENT', 'COMPANYID', 'COMPANYNAME', 'COSTCENTREID', 'COSTCENTRENAME', 'CPK', 'CPK_NORM', 'CREATEDATETIME', 'CUSTOMERID', 'CUSTOMERNORM', 'DRIVERID', 'DRIVERNAME', 'DivisionId', 'DivisionName', 'ExportInd1', 'ExportInd2', 'FROMFUELSTATIONID', 'FROMFUELSTATIONNAME', 'FUELCOMPANYID', 'FromLoc', 'FstatInternal', 'HORSETRAILER', 'HOURSTOTRAILER', 'IGNORECALC', 'INVOICENO', 'LITRESATNORM', 'LITRESTOTRAILER', 'LPERHOUR', 'MATCHED', 'MODIFYDATETIME', 'MONTHEXCEED', 'MVA_NUMBER', 'MailSent', 'NEWIMPORT', 'NORM', 'NotActiveStatus', 'OILCOMPANYNAME', 'ORDERNO', 'PICKUPTYPE', 'PRICEPERLITRE', 'PUMPATTENDANT', 'SealNo1', 'SealNo2', 'TANKCAPACITY', 'TOFUELSTATIONID', 'TOFUELSTATIONNAME', 'TOWN', 'TRANSACTIONID', 'TRIPKM', 'Tel', 'ToLoc', 'VEHICLEID', 'VOUCHERNO'],
        })  
      }

      if(this.export_as=="csv"){
        const filename = 'CSV_IFM_Transcations ('+this.data.selected_transcations.length+') - '+Math.floor(Math.random() * 1000000);
        exporter.toCSV(this.data.selected_transcations, filename, {
          headers: ['REGISTRATIONNO', 'FLEETNO', 'DATETIME', 'TANKFULL','NEWODO', 'CURRENTODO', 'PREVIOUSODO', 'TRIPLITRES', 'CALCLITRES', 'CALCKM', 'CONSUMPTIONKML', 'CONSUMPTIONL100', 'LOSSAMOUNT', 'LOSSLITRES', 'PERCLOSS', 'ACCOUNTID', 'ACCOUNTNAME', 'AMOUNT', 'AS400COSTCENTREID', 'AS400CUSTOMERID', 'AS400ACCOUNTID', 'AS400CompanyId', 'CARDNO', 'CARDNOID', 'COMMENT', 'COMPANYID', 'COMPANYNAME', 'COSTCENTREID', 'COSTCENTRENAME', 'CPK', 'CPK_NORM', 'CREATEDATETIME', 'CUSTOMERID', 'CUSTOMERNORM', 'DRIVERID', 'DRIVERNAME', 'DivisionId', 'DivisionName', 'ExportInd1', 'ExportInd2', 'FROMFUELSTATIONID', 'FROMFUELSTATIONNAME', 'FUELCOMPANYID', 'FromLoc', 'FstatInternal', 'HORSETRAILER', 'HOURSTOTRAILER', 'IGNORECALC', 'INVOICENO', 'LITRESATNORM', 'LITRESTOTRAILER', 'LPERHOUR', 'MATCHED', 'MODIFYDATETIME', 'MONTHEXCEED', 'MVA_NUMBER', 'MailSent', 'NEWIMPORT', 'NORM', 'NotActiveStatus', 'OILCOMPANYNAME', 'ORDERNO', 'PICKUPTYPE', 'PRICEPERLITRE', 'PUMPATTENDANT', 'SealNo1', 'SealNo2', 'TANKCAPACITY', 'TOFUELSTATIONID', 'TOFUELSTATIONNAME', 'TOWN', 'TRANSACTIONID', 'TRIPKM', 'Tel', 'ToLoc', 'VEHICLEID', 'VOUCHERNO'],
        })  
      }
    }
    
    if(this.export_method=="all-transcations"){
      if(this.export_as=="excel"){
        const filename = 'XLSX_IFM_Transcations ('+this.data.all_transcations.length+') - '+Math.floor(Math.random() * 1000000);
        exporter.toXLSX(this.data.all_transcations, filename, {
          headers: ['REGISTRATIONNO', 'FLEETNO', 'DATETIME', 'TANKFULL','NEWODO', 'CURRENTODO', 'PREVIOUSODO', 'TRIPLITRES', 'CALCLITRES', 'CALCKM', 'CONSUMPTIONKML', 'CONSUMPTIONL100', 'LOSSAMOUNT', 'LOSSLITRES', 'PERCLOSS', 'ACCOUNTID', 'ACCOUNTNAME', 'AMOUNT', 'AS400COSTCENTREID', 'AS400CUSTOMERID', 'AS400ACCOUNTID', 'AS400CompanyId', 'CARDNO', 'CARDNOID', 'COMMENT', 'COMPANYID', 'COMPANYNAME', 'COSTCENTREID', 'COSTCENTRENAME', 'CPK', 'CPK_NORM', 'CREATEDATETIME', 'CUSTOMERID', 'CUSTOMERNORM', 'DRIVERID', 'DRIVERNAME', 'DivisionId', 'DivisionName', 'ExportInd1', 'ExportInd2', 'FROMFUELSTATIONID', 'FROMFUELSTATIONNAME', 'FUELCOMPANYID', 'FromLoc', 'FstatInternal', 'HORSETRAILER', 'HOURSTOTRAILER', 'IGNORECALC', 'INVOICENO', 'LITRESATNORM', 'LITRESTOTRAILER', 'LPERHOUR', 'MATCHED', 'MODIFYDATETIME', 'MONTHEXCEED', 'MVA_NUMBER', 'MailSent', 'NEWIMPORT', 'NORM', 'NotActiveStatus', 'OILCOMPANYNAME', 'ORDERNO', 'PICKUPTYPE', 'PRICEPERLITRE', 'PUMPATTENDANT', 'SealNo1', 'SealNo2', 'TANKCAPACITY', 'TOFUELSTATIONID', 'TOFUELSTATIONNAME', 'TOWN', 'TRANSACTIONID', 'TRIPKM', 'Tel', 'ToLoc', 'VEHICLEID', 'VOUCHERNO'],
        })  
      }

      if(this.export_as=="csv"){
        const filename = 'CSV_IFM_Transcations ('+this.data.all_transcations.length+') - '+Math.floor(Math.random() * 1000000);
        exporter.toCSV(this.data.all_transcations, filename, {
          headers: ['REGISTRATIONNO', 'FLEETNO', 'DATETIME', 'TANKFULL','NEWODO', 'CURRENTODO', 'PREVIOUSODO', 'TRIPLITRES', 'CALCLITRES', 'CALCKM', 'CONSUMPTIONKML', 'CONSUMPTIONL100', 'LOSSAMOUNT', 'LOSSLITRES', 'PERCLOSS', 'ACCOUNTID', 'ACCOUNTNAME', 'AMOUNT', 'AS400COSTCENTREID', 'AS400CUSTOMERID', 'AS400ACCOUNTID', 'AS400CompanyId', 'CARDNO', 'CARDNOID', 'COMMENT', 'COMPANYID', 'COMPANYNAME', 'COSTCENTREID', 'COSTCENTRENAME', 'CPK', 'CPK_NORM', 'CREATEDATETIME', 'CUSTOMERID', 'CUSTOMERNORM', 'DRIVERID', 'DRIVERNAME', 'DivisionId', 'DivisionName', 'ExportInd1', 'ExportInd2', 'FROMFUELSTATIONID', 'FROMFUELSTATIONNAME', 'FUELCOMPANYID', 'FromLoc', 'FstatInternal', 'HORSETRAILER', 'HOURSTOTRAILER', 'IGNORECALC', 'INVOICENO', 'LITRESATNORM', 'LITRESTOTRAILER', 'LPERHOUR', 'MATCHED', 'MODIFYDATETIME', 'MONTHEXCEED', 'MVA_NUMBER', 'MailSent', 'NEWIMPORT', 'NORM', 'NotActiveStatus', 'OILCOMPANYNAME', 'ORDERNO', 'PICKUPTYPE', 'PRICEPERLITRE', 'PUMPATTENDANT', 'SealNo1', 'SealNo2', 'TANKCAPACITY', 'TOFUELSTATIONID', 'TOFUELSTATIONNAME', 'TOWN', 'TRANSACTIONID', 'TRIPKM', 'Tel', 'ToLoc', 'VEHICLEID', 'VOUCHERNO'],
        })  
      }
    }
    this.openSnackBar('Download started', 'Okay', 'success');

    }


}
