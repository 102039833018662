import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/companies/company.service';
import { DriverService } from 'src/app/services/drivers/driver.service';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.css'],
})
export class AddDriverComponent {
  constructor(
    public dialogRef: MatDialogRef<AddDriverComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _snackbar: SnackbarsService,
    private _driverServ: DriverService,
    private _companyServ: CompanyService
  ) {}


  addDriverForm = this.formBuilder.group({
    COMPANYID: [this._companyServ.getCompanyId(), Validators.required],
    COMAPNYNAME: [this._companyServ.getCompanyName(), Validators.required],
    DRIVERID: ['', Validators.required],
    AS400DRIVERID: [1, Validators.required],
    DRIVERNAME: ['', Validators.required],
    EMPLOYEENUMBER: ['', Validators.required],
    IDNUMBER: ['', Validators.required],
    ISACTIVE: [0, Validators.required],
    PASSPORTNUMBER: ['', Validators.required],
  });

  onSubmit() {
    try {
      this._driverServ.createNewDriver(this.addDriverForm.value).subscribe({
        next: (data: any) => {
          if (data.statusmessage != 'Data already existing') {

            this.closeModal(true);
          }
          this._snackbar.success(data.statusmessage);
        },
      });
    } catch (error: any) {
      this._snackbar.error(error.message);
    }
  }

  closeModal(data?: any): void {
    this.dialogRef.close({ event: data });
  }
}
