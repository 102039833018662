<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="lft">
        <p>Add Fuel Station</p>
        <p>........................................</p>
      </div>
      <div class="rgt">
        <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
      </div>
    </div>

    <div class="modal-contain">

        <form class="myform">
            <!-- <div class="htitle">
                <p class="FxLine">
                    Vehicle Information
                </p>
            </div> -->
            <div class="row">
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Fuel Station Master Id</mat-label>
                        <input matInput value="" placeholder="Ex. 1234">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>AS400 Fuel Station Id</mat-label>
                        <input matInput value="" placeholder="Ex. 1234">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Fuel Oil Company Id</mat-label>
                        <input matInput value="" placeholder="Ex. 1234">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Registered Name</mat-label>
                        <input matInput value="" placeholder="Ex. Registered Name">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Trading Name</mat-label>
                        <input matInput value="" placeholder="Ex. Trading Name">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Town</mat-label>
                        <input matInput value="" placeholder="Ex. Town Name">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Merchant Number</mat-label>
                        <input matInput value="" placeholder="Ex. 1234">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Email Address</mat-label>
                        <input matInput value="" placeholder="Ex. emailaddress@gmail.com">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Province</mat-label>
                        <input matInput value="" placeholder="Ex. Province Name">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Zone</mat-label>
                        <input matInput value="" placeholder="Ex. Zone Name">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Telephone</mat-label>
                        <input matInput value="" placeholder="Ex. 1234">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Cell</mat-label>
                        <input matInput value="" placeholder="Ex. 08123456789">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Fax</mat-label>
                        <input matInput value="" placeholder="Ex. 0123456789">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Magisterial District</mat-label>
                        <input matInput value="" placeholder="Ex. Magisterial District">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>Contact Person</mat-label>
                        <input matInput value="" placeholder="Ex. 1234">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>GPS Longitude</mat-label>
                        <input matInput value="" placeholder="Ex. 1234">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field >
                        <mat-label>GPS Latitude</mat-label>
                        <input matInput value="" placeholder="Ex. 1234">
                    </mat-form-field>
                </div>
                <div class="col-xs-12">
                    <button class="mybtn" mat-flat-button color="primary" type="submit">Add Fuel Station</button>
                </div>
            </div>



        </form>
    </div>
  </div>
