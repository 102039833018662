<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>Manage Account Billing</p>
      <p>{{data.ACCOUNTNAME}}</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain">
    <form
      class="myform"
      [formGroup]="accountBillingForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>Company Id</mat-label>
            <input
              formControlName="COMPANYID"
              matInput
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>AS400 Company Id</mat-label>
            <input
              formControlName="AS400COMPANYID"
              matInput
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>Company Name</mat-label>
            <input
              matInput
              formControlName="COMPANYNAME"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>

        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>IFM Account Id</mat-label>
            <input
              matInput
              formControlName="ACCOUNTID"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>AS400 Account Id</mat-label>
            <input
              matInput
              formControlName="AS400ACCOUNTID"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>Account Name</mat-label>
            <input
              matInput
              formControlName="ACCOUNTNAME"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>

        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>AS400 Customer Id</mat-label>
            <input
              matInput
              formControlName="AS400CUSTOMERID"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>Customer Id</mat-label>
            <input
              matInput
              formControlName="CUSTOMERID"
              placeholder="Ex. ABCD"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>Customer Name</mat-label>
            <input
              matInput
              formControlName="CUSTOMERNAME"
              placeholder="Ex. ABCD"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>Billing Id</mat-label>
            <input
              matInput
              formControlName="BILLINGID"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field appearance="fill">
            <mat-label>Billing Type</mat-label>
            <mat-select
              formControlName="BILLINGTYPE"
              [value]="data.BillingType"
              (selectionChange)="assignDesc($event.value)" >
              <mat-option value="FC">FC</mat-option>
              <mat-option value="FR">FR</mat-option>
              <mat-option value="FW">FW </mat-option>
              <mat-option value="VU">VU</mat-option>
              <mat-option value="NA">NA</mat-option>
              <mat-option value="LF">LF</mat-option>
              <mat-option value="FA">FA</mat-option>
              <mat-option value="FM">FM</mat-option>
              <mat-option value="BS">BS</mat-option>
              <mat-option value="BC">BC</mat-option>
              <mat-option value="BF">BF</mat-option>
              <mat-option value="SF">SF</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>Billing Desciption</mat-label>
            <input
              matInput
              formControlName="BILLINGDESCRIPTION"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>Qty(When BS/VU is selected, ignore this)</mat-label>
            <input
              matInput
              formControlName="QTY"
              type="number"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>First Check Day</mat-label>
            <input
              matInput
              formControlName="FIRSTCHECKDAY"
              type="number"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>Amount</mat-label>
            <input
              matInput
              formControlName="AMOUNT"
              type="number"
              placeholder="Ex. 0.00"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
          <mat-slide-toggle
            formControlName="NOTACTIVE"
            color="primary"
            class="myswitch"
            ><p>Active</p></mat-slide-toggle >
        </div>
        <div class="col-xs-12">
          <button class="mybtn" mat-flat-button color="primary" type="submit">
            save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
