import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyService } from '../companies/company.service';

@Injectable({
  providedIn: 'root',
})
export class DriverService {
  constructor(private http: HttpClient, private _companyServ: CompanyService) {}

  private service_base = "https://zedadev.apigw-aw-eu.webmethods.io/gateway/IFM/1.0/"

  private headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });

  createNewDriver(data: any) {
    let url = this.service_base+'createDriver';
    return this.http.post<any>(url, {
      DriverInfo: data,
    },{
      headers:this.headers
    });
  }
  updateDriver(data: any) {
    let url = this.service_base+'updateDriverName';
    return this.http.put<any>(url,
       data
    ,{
      headers:this.headers
    });
  }

  getAllDrivers(): Observable<any> {
    let url = this.service_base+'getAllDrivers';
    return this.http.get<any[]>(url,{
      headers:this.headers
    });
  }

  getDriverById(id: any): Observable<any> {
    let url = this.service_base+'getDriverByDriverID?DriverID=' + id;
    return this.http.get<any[]>(url,{
      headers:this.headers
    });
  }

  getDriversByCompanyId(id: any): Observable<any> {
    let url = this.service_base+'getDriversByCompanyID?CompanyID=' + id;
    return this.http.get<any[]>(url,{
      headers:this.headers
    });
  }

  deleteDriver(id: any): Observable<any> {
    let url = this.service_base+'deleteDriverByDriverId?DriverId=' + id;
    return this.http.delete<any[]>(url,{
      headers:this.headers
    });
  }

  getDriverKPI(id?: any): Observable<any> {
    let companyID = id ? id : this._companyServ.getCompanyId();
    let url = this.service_base+'getDriverCountByCID?CompanyID=' + companyID;
    return this.http.get<any[]>(url,{
      headers:this.headers
    });
  }
}
