import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { CompanyService } from 'src/app/services/companies/company.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Company } from 'src/app/interfaces/company';

@Component({
  selector: 'app-scorecard-paramater-config',
  templateUrl: './scorecard-paramater-config.component.html',
  styleUrls: ['./scorecard-paramater-config.component.css']
})
// interface Company{

// }
export class ScorecardParamaterConfigComponent {
  constructor(
    public dialogRef: MatDialogRef<ScorecardParamaterConfigComponent>,
    private ReportsService: ReportsService,
    private CompanyService: CompanyService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  myControl = new FormControl('');
  options: Company[];
  filteredOptions: Observable<string[]>;
  private _filter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.options.filter((option: any) => option.COMPANYNAME.toLowerCase().includes(filterValue));
  }

  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, { duration: 5000, panelClass: type });
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  initForm() {

  }


  getCheckedInputs() {
    let checkedInputs = 0;
    if (this.data.form.NormalWeekend == '1') {
      checkedInputs++;
    }
    if (this.data.form.ExtendedWeekend == '1') {
      checkedInputs++;
    }
    if (this.data.form.MonthlyLitresSet == '1') {
      checkedInputs++;
    }
    if (this.data.form.FS_OutOfBounds == '1') {
      checkedInputs++;
    }
    if (this.data.form.SameDayRefill == '1') {
      checkedInputs++;
    }
    if (this.data.form.TankCapExceed == '1') {
      checkedInputs++;
    }
    if (this.data.form.DriverResponse == '1') {
      checkedInputs++;
    }
    if (this.data.form.MonthlyKmSet == '1') {
      checkedInputs++;
    }
    if (this.data.form.CashTrans == '1') {
      checkedInputs++;
    }
    return checkedInputs;
  }

  getCalculatedPerc() {
    let lossPer = 100 - Number(this.data.form.LossesPer);
    let totalPerc = 0;
    if (this.getCheckedInputs() > 0) {
      totalPerc = Number((lossPer / this.getCheckedInputs()).toFixed(2));
    }
    if (totalPerc < 0) {
      totalPerc = 0;
    }
    this.data.form.NormalWeekendPer = totalPerc;
    this.data.form.ExtendedWeekendPer = totalPerc;
    this.data.form.MonthlyLitresSetPer = totalPerc;
    this.data.form.FS_OutOfBoundsPer = totalPerc;
    this.data.form.SameDayRefillPer = totalPerc;
    this.data.form.TankCapExceedPer = totalPerc;
    this.data.form.DriverResponsePer = totalPerc;
    this.data.form.MonthlyKmSetPer = totalPerc;
    this.data.form.CashTransPer = totalPerc;
  }

  onChangeCheckbox(fieldName: string, isChecked: boolean) {
    let status = 0;
    if (isChecked) {
      status = 1;
    }

    this.data.form[fieldName] = status;
    this.getCalculatedPerc();
  }

  onChangeValue(fieldName: string, value: any) {
    this.data.form[fieldName] = value;
    if (fieldName == 'LossesPer') {
      this.getCalculatedPerc()
    }
  }

  response = '';
  addScorecardParameter() {
    let payload = this.data.form;
    this.response = 'loading';
    this.ReportsService.addScoreCardParameter(payload).subscribe({
      next: (data: any) => {
        console.log(data)
        if (data && data.length > 0) {
          this.response = 'success';
        } else {
          this.response = 'no_results';
          this.openSnackBar('No results', 'Okay', 'success');
        }
      },
      error: (error: any) => {
        this.openSnackBar('Check internet connectivity', 'Okay', 'error');
        this.response = 'network_err';
      },
    });
  }
  updateScorecardParameter() {
    let payload = this.data.form;
    this.response = 'loading';
    this.ReportsService.updateScoreCardParameter(payload).subscribe({
      next: (data: any) => {
        console.log(data)
        if (data && data.rowsAffected > 0) {
          this.response = 'success';
          this.openSnackBar('Successfully updated', 'Okay', 'success');
        } else {
          this.response = 'no_results';
          this.openSnackBar('No results', 'Okay', 'success');
        }
      },
      error: (error: any) => {
        this.openSnackBar('Check internet connectivity', 'Okay', 'error');
        this.response = 'network_err';
      },
    });
  }

  fetch_companies_response = '';
  FetchAllCompanies() {
    this.fetch_companies_response = 'loading';
    this.CompanyService.getAllCompanies().subscribe({
      next: (data: any) => {
        if (data.results && data.results.length > 0) {
          console.log(data.response)
          this.options = data.response.map((option: any) => {
            return { 'COMPANYNAME': option.COMPANYNAME, 'COMPANYID': option.COMPANYID }
          });
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
          );
          console.log(this.options);
          this.fetch_companies_response = 'success';
        } else {
          this.fetch_companies_response = 'no_results';
          this.openSnackBar('No results', 'Okay', 'success');
        }
      },
      error: (error: any) => {
        this.openSnackBar('Check internet connectivity', 'Okay', 'error');
        this.fetch_companies_response = 'network_err';
      },
    });
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.initForm()
  }
  ngAfterViewInit() {
    this.FetchAllCompanies();

  }
}
