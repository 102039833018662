import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
import { Component, Inject, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/companies/company.service';
import { TransactionService } from 'src/app/services/transactions/transaction.service';

@Component({
  selector: 'app-view-transaction',
  templateUrl: './view-transaction.component.html',
  styleUrls: ['./view-transaction.component.css'],
})
export class ViewTransactionComponent {
  constructor(
    public dialogRef: MatDialogRef<ViewTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _tranServ: TransactionService,
    private _companyServe: CompanyService,
    private _snackbar: SnackbarsService
  ) {}
  calc_status = 'complete';
  formData: any;

  updateTransactionForm = this.formBuilder.group({
    RegistrationNo: [this.data[0].REGISTRATIONNO, Validators.required],
    acceptTerms: [''],
    FuelStation: [this.data[0].FROMFUELSTATIONNAME, Validators.required],
    fullCompany: [this.data[0].COMPANYNAME, Validators.required],
    litrePrice: [this.data[0].PRICEPERLITRE.toString(), Validators.required],
    Litres: [this.data[0].TRIPLITRES.toString(), Validators.required],
    OdoReading: [this.data[0].CURRENTODO.toString(), Validators.required],
    ReceiptNo: [this.data[0].INVOICENO, Validators.required],
    DayOfWeek: [''],
    driverName: [this.data[0].DRIVERNAME, Validators.required],
    time: [this.data[0].DATETIME, Validators.required],
    makeRange: [''],
    town: [this.data[0].TOWN, Validators.required],
    companyId: [this.data[0].COMPANYID.toString(), Validators.required],
    Amount: [this.data[0].AMOUNT.toString(), Validators.required],
    num1: [''],
    Action: ['UPDATE'],
  });

  onSubmit() {
    try {
      this._tranServ
        .PostFuelTransactions(
          [this.updateTransactionForm.value],
          this._companyServe.getCompanyId(),
          ''
        )
        .subscribe({
          next: (data) => {
            this.calc_status = 'loading';
            document.getElementById('reload_transaction_table')!.click();
            document.getElementById('reload_recon_report')!.click();
            this.calc_status = 'complete';
          },
        });
      this._snackbar.success('Successfully updated');
      this.closeModal(this.updateTransactionForm.value);
    } catch (error) {}
  }

  closeModal(data?: any): void {
    this.dialogRef.close({ event: data });
  }

  ngAfterViewInit(): void {
    this.formData = this.data[0];
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // this.data[0].AMOUNT
  }
}
