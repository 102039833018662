import { Component, EventEmitter, Output } from '@angular/core';
import { CompanyService } from '../../../services/companies/company.service';
import { Company } from '../../../models/company';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';

@Component({
  selector: 'app-main-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.css'],
})
export class CompanySelectComponent {
  userCompanies: Company[] = [];
  @Output() selectedCompany = new EventEmitter();

  constructor(
    private _companyService: CompanyService,
    private _snackbar: SnackbarsService,
    private router: Router,
    private _storageServ: StorageService,
    private _snackBar: MatSnackBar,
    private auth: AuthService
  ) {}

  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, { duration: 5000, panelClass: type });
  }

  fetch_response = 'loading';

  fetch_data: any = [];
  raw_data: any = [];

  fetch_companies() {
    this.fetch_response = 'loading';
    this._companyService.getUserCompanies(this._storageServ.getUserId()).subscribe({
      next: (data: any) => {
        if (data.results && data.results.length > 0) {
          this.fetch_response = 'success';
          this.fetch_data = data.results;
          this.raw_data = data.results;
          
          this._snackbar.success('Hi ' + this._storageServ.getUserName());
        } else {
          this.fetch_response = 'no_results';
          if(data.status.message == 'ConnectionError'){
            this.fetch_response = 'network_err';
          }
        }
      },
      error: (error:any) => {
        this.fetch_response = 'network_err';
        this.openSnackBar(error.message, 'Retry', 'error');
      },
    });
  }

  ngOnInit() {
    if(this._storageServ.getItem('username')){
      this.fetch_companies();
      document.title = "IFM - Select Company";
    }else{
      this.router.navigate(['/login']);
    }

  }

  //initial Company Select
  selectCompany(r: any) {
    sessionStorage.setItem('company_id', r.COMPANYID);
    sessionStorage.setItem('company_selected', r.COMPANYNAME);
    sessionStorage.setItem('AVISWESBANKID', r.AvisWesbankId ? r.AvisWesbankId.replaceAll(' ', '') : '');
    this.router.navigate(['/dashboard/1']);
  }

  handle_search(event: any) {
    var value = event.value;
    if (this.raw_data.length > 0) {
      const results = this.raw_data.filter((x: any) => {
        return x.COMPANYNAME.toLowerCase().includes(value.toLowerCase());
      });
      this.fetch_data = results;
    }
  }

  logout() {
    this.auth.logout();
  }
}
