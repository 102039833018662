<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p><i class="fa fa-building"></i> {{ data[0].COMPANYNAME }} Details</p>
      <p>View Or Update {{ data[0].COMPANYNAME }} Setup.</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>
  <div class="myModal">
    <div class="modal-contain">
      <mat-menu #userMenu="matMenu">
        <ng-template matMenuContent let-data="data">
          <button
            mat-menu-item
            *ngIf="data.SETFOR == 'account'"
            (click)="openAddBillingAccount(data)"
          >
            <i class="fa fa-user-cog primary"></i> View Account Billing
          </button>
        </ng-template>
      </mat-menu>
      <div class="myuitabs">
        <div class="lft">
          <button
            mat-stroked-button
            [ngClass]="tab === '1' ? 'active' : ''"
            (click)="selectTab('1')"
            type="button"
          >
            <p>Company Information</p>
          </button>
          <button
            mat-stroked-button
            [ngClass]="tab === '2' ? 'active' : ''"
            (click)="selectTab('2')"
            type="button"
          >
            <p>Customers</p>
          </button>
          <button
            mat-stroked-button
            [ngClass]="tab === '3' ? 'active' : ''"
            (click)="selectTab('3')"
            type="button"
          >
            <p>Accounts</p>
          </button>
          <button
            mat-stroked-button
            [ngClass]="tab === '4' ? 'active' : ''"
            (click)="selectTab('4')"
            type="button"
          >
            <p>Cost Centre</p>
          </button>
        </div>
        
      </div>
      <ng-container *ngIf="tab === '1'">
        <form
          class="myform"
          [formGroup]="viewCompanyForm"
          (ngSubmit)="onUpdate()"
          novalidate
        >
          <div class="row col-12">
            <div class="col-7">
              <div class="row">
                <div class="col-xs-12 col-md-12">
                  <!-- value="this.viewCompanyForm.controls['CUSTOMER']" -->

                  <mat-form-field>
                    <mat-label>Company Name</mat-label>
                    <input
                      matInput
                      placeholder="Ex. Company Name"
                      [(ngModel)]="data[0].COMPANYNAME"
                      formControlName="COMPANYNAME"
                      required
                    />
                  </mat-form-field>
                  <!-- <mat-error
                        *ngIf="
                          viewCompanyForm.controls['COMPANYNAME'].hasError('required')
                        "
                      >
                        Company Name field is requied.
                      </mat-error> -->
                </div>

                <div class="col-xs-12 col-md-3">
                  <mat-form-field appearance="fill">
                    <mat-label>Currency Symbol</mat-label>
                    <mat-select
                      formControlName="CURRENCYSYMBOL"
                      [(ngModel)]="data[0].CURRENCYSYMBOL"
                    >
                      <mat-option value="R">R</mat-option>
                      <mat-option value="$">$</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                  <mat-form-field appearance="fill">
                    <mat-label>TRAILING</mat-label>
                    <mat-select
                      formControlName="TRAILING"
                      [(ngModel)]="data[0].TRAILING"
                    >
                      <mat-option [value]="1">Yes</mat-option>
                      <mat-option [value]="0">No</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Distance</mat-label>
                    <mat-select
                      formControlName="DISTANCEID"
                      [(ngModel)]="data[0].DISTANCEID"
                    >
                      <mat-option
                        *ngFor="let distance of distance.distances"
                        [value]="distance.DISTANCEID"
                        >{{ distance.FROMTOWNNAME }} -
                        {{ distance.TOTOWNNAME }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                  <mat-form-field>
                    <mat-label>Fuel Measure ID</mat-label>
                    <mat-select
                      formControlName="FUELMEASUREID"
                      [(ngModel)]="data[0].FUELMEASUREID"
                    >
                      <mat-option [value]="1">Litres</mat-option>
                      <mat-option [value]="2">Gallons</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                  <mat-form-field>
                    <mat-label>Perc Lower Limit</mat-label>
                    <input
                      matInput
                      [(ngModel)]="data[0].PERCLOWERLIMIT"
                      type="number"
                      formControlName="PERCLOWERLIMIT"
                      placeholder="Ex. 0.00"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                  <mat-form-field>
                    <mat-label>Perc Upper Limit</mat-label>
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="data[0].PERCUPPERLIMIT"
                      formControlName="PERCUPPERLIMIT"
                      placeholder="Ex. 0.00"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                  <mat-form-field>
                    <mat-label>Perc No Loss</mat-label>
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="data[0].PERCNOLOSS"
                      formControlName="PERCNOLOSS"
                      placeholder="Ex. 0.00"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                  <mat-form-field>
                    <mat-label>Tank Exceed</mat-label>
                    <input
                      matInput
                      [(ngModel)]="data[0].TANKEXCEED"
                      formControlName="TANKEXCEED"
                      placeholder="Ex. 10%"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                  <mat-form-field>
                    <mat-label>Days To Edit</mat-label>
                    <input
                      matInput
                      [(ngModel)]="data[0].DAYSTOEDIT"
                      formControlName="DAYSTOEDIT"
                      value="{{ data[0].DAYSTOEDIT }}"
                      placeholder="Ex. 0"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                  <mat-form-field>
                    <mat-label>Lper Hour Calculation</mat-label>
                    <input
                      matInput
                      [(ngModel)]="data[0].LperHourCalc"
                      formControlName="LPERHOURCALCULATION"
                      value="{{ data[0].LperHourCalc }}"
                      placeholder="Ex. 0.00"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                  <mat-form-field>
                    <mat-label>High Odo</mat-label>
                    <input
                      matInput
                      [(ngModel)]="data[0].HighOdoValue"
                      formControlName="HIGHODO"
                      placeholder="Ex. 0.00"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-12">
                  <div class="row">
                    <div class="col-xs-12 col-md-3">
                      <mat-form-field>
                        <mat-label>Telephone</mat-label>
                        <input
                          matInput
                          [(ngModel)]="data[0].Telephone"
                          formControlName="TEL"
                          placeholder="Ex. 01124567890"
                          value="{{ data[0].Telephone }}"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-md-3">
                      <mat-form-field>
                        <mat-label>Cell</mat-label>
                        <input
                          matInput
                          [(ngModel)]="data[0].Cell"
                          formControlName="CELL"
                          placeholder="Ex. 08124567890"
                          value="{{ data[0].Cell }}"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-md-3">
                      <mat-form-field>
                        <mat-label>Contact Person</mat-label>
                        <input
                          matInput
                          [(ngModel)]="data[0].ContactPerson"
                          formControlName="CONTACTPERSON"
                          placeholder="Ex. Name Surname"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-md-3">
                      <mat-form-field>
                        <mat-label>Avis Wesbank Id</mat-label>
                        <input
                          matInput
                          [(ngModel)]="data[0].AvisWesbankId"
                          formControlName="AVISWESBANKID"
                          placeholder="Ex. 1234"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 mt-2">
                  <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="row"
                    [(ngModel)]="selectedDriverImportOption"
                  >
                    <mat-radio-button
                      class="col-md-4"
                      *ngFor="let import_option of importDriverOptions"
                      [(ngModel)]="selectedOption"
                      [value]="import_option.value"
                      [checked]="import_option.checked"
                      (change)="radioChange($event)"
                      >{{ import_option.name }}</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </div>
            </div>
            <div class="col-5">
              <div class="searchbox">
                <i class="fa-solid fa-magnifying-glass"></i>
                <input
                  type="text"
                  [(ngModel)]="seacrchbox"
                  (keyup)="searchArray()"
                  placeholder="Assign AS400 Customers"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
              <div class="cardscontainer">
                <div
                  class="card001"
                  [class]="'active' ? '' : 'active'"
                  *ngFor="let r of fetch_data"
                  (click)="AssignCustomer(r)"
                >
                  <div class="lft">
                    <i class="fa-regular fa-user"></i>
                    <div class="titles">
                      <p>{{ r.CUSTOMERNAME }}</p>
                      <p>{{ r.AS400CUSTOMERID }}</p>
                    </div>
                  </div>
                  <div class="rgt">
                    <mat-checkbox
                      [checked]="r.checked"
                      color="primary"
                    ></mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-12" *ngIf="showing == 'editing_transcation'"> -->
            <div class="col-12" align="end">
              <button
                class="mybtn"
                mat-flat-button
                color="primary"
                type="submit"
              >
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="tab === '2'">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="card007 standlone-x3">
              <div class="mytable">
                <table
                  mat-table
                  [dataSource]="dataSource_customers"
                  class="mat-elevation-z8"
                >
                  <ng-container matColumnDef="CUSTOMER_ID">
                    <th mat-header-cell *matHeaderCellDef>Customer Id</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getCustomerEmailAddresses(row)"
                    >
                      {{ row.CUSTOMERID }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="AS400CUSTOMERID">
                    <th mat-header-cell *matHeaderCellDef>AS400 Customer ID</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getCustomerEmailAddresses(row)"
                    >
                      {{ row.AS400CUSTOMERID }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="CUSTOMERNAME">
                    <th mat-header-cell *matHeaderCellDef>Customer Name</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getCustomerEmailAddresses(row)"
                    >
                      {{ row.CUSTOMERNAME }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="AS400COMPANYID">
                    <th mat-header-cell *matHeaderCellDef>AS400 Company ID</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      [matMenuTriggerFor]="userMenu"
                      [matMenuTriggerData]="{ data: row }"
                    >
                      {{ row.AS400CompanyId }}
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns_Customers; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns_Customers"
                  ></tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="card007">
              <div class="mytable">
                <table
                  mat-table
                  [dataSource]="dataSource_Customer_Email_Addresses"
                  class="mat-elevation-z8"
                >
                  <ng-container matColumnDef="EMAIL_ADDRESS">
                    <th mat-header-cell *matHeaderCellDef>Email Address</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="updateEmailConfig(row)"
                    >
                      {{ row.EmailAddress }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="EMAIL_TYPE_DESCRIPTION">
                    <th mat-header-cell *matHeaderCellDef>
                      Email Type Description
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="updateEmailConfig(row)"
                    >
                      {{ row.MailTypeDescription }}
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="
                      displayedColumns_Customer_Email_Addresses;
                      sticky: true
                    "
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="
                      let row;
                      columns: displayedColumns_Customer_Email_Addresses
                    "
                  ></tr>
                </table>
              </div>
            </div>
            <div class="actionsbtns">
              <div class="myrow">
                <button
                  mat-stroked-button
                  class="active"
                  (click)="insertEmail(setup_selected_value)"
                >
                  <p>Insert</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>Change</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>Delete</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>Delete All</p>
                </button>
              </div>
              <div class="myrow">
                <button mat-stroked-button class="active">
                  <p>Change Email Address</p>
                </button>
                <button mat-stroked-button class="active">
                  <mat-checkbox [(ngModel)]="ccDoAll">
                    <p>Do for all Companies</p></mat-checkbox
                  >
                </button>
              </div>
              <div class="myrow">
                <button mat-stroked-button class="active">
                  <p>From Email Address ID : 0</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>From Email Address ID : 0</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="tab === '3'">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="card007 standlone-x3">
              <div class="mytable">
                <table
                  mat-table
                  [dataSource]="dataSource_accounts"
                  class="mat-elevation-z8"
                >
                  <ng-container matColumnDef="ACCOUNT_ID">
                    <th mat-header-cell *matHeaderCellDef>Account ID</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getAccountEmailAddresses(row)"
                    >
                      {{ row.ACCOUNTID }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="AS400ACCOUNTID">
                    <th mat-header-cell *matHeaderCellDef>AS400 Account ID</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getAccountEmailAddresses(row)"
                    >
                      {{ row.AS400ACCOUNTID }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="ACCOUNTNAME">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getAccountEmailAddresses(row)"
                    >
                      {{ row.ACCOUNTNAME }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="COMPANYNAME">
                    <th mat-header-cell *matHeaderCellDef>COMPANY NAME</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getAccountEmailAddresses(row)"
                      [matMenuTriggerFor]="userMenu"
                      [matMenuTriggerData]="{ data: row }"
                    >
                      {{ row.COMPANYNAME }}
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns_Accounts; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns_Accounts"
                  ></tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="card007">
              <div class="mytable">
                <table
                  mat-table
                  [dataSource]="dataSource_Account_Email_Addresses"
                  class="mat-elevation-z8"
                >
                  <ng-container matColumnDef="EMAIL_ADDRESS">
                    <th mat-header-cell *matHeaderCellDef>Email Address</th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.EMAILADDRESS }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="EMAIL_TYPE_DESCRIPTION">
                    <th mat-header-cell *matHeaderCellDef>
                      Email Type Description
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.MAILTYPEDESCRIPTION }}
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="
                      displayedColumns_Email_Addresses;
                      sticky: true
                    "
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="
                      let row;
                      columns: displayedColumns_Email_Addresses
                    "
                  ></tr>
                </table>
              </div>
            </div>
            <div class="actionsbtns">
              <div class="myrow">
                <button
                  mat-stroked-button
                  class="active"
                  (click)="insertEmail(setup_selected_value)"
                >
                  <p>Insert</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>Change</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>Delete</p>
                </button>
              </div>
              <div class="myrow">
                <button mat-stroked-button class="active">
                  <p>Change Email Address</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>Dublicate Same Email For All Types</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>Delete Emails for CC</p>
                </button>
              </div>
              <div class="myrow">
                <button mat-stroked-button class="active">
                  <mat-checkbox [(ngModel)]="ccDoAll">
                    <p>Do for all CC</p></mat-checkbox
                  >
                </button>
                <button mat-stroked-button class="active">
                  <p>From Email ID : 0</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>To Email ID : 0</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="tab === '4'">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="card007 standlone-x3">
              <div class="mytable">
                <table
                  mat-table
                  [dataSource]="dataSource_Cost_Center"
                  class="mat-elevation-z8"
                >
                  <ng-container matColumnDef="COSTCENTREID">
                    <th mat-header-cell *matHeaderCellDef>Cost Centre ID</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getCostCentreEmailAddresses(row)"
                    >
                      {{ row.COSTCENTREID }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="AS400COSTCENTREID">
                    <th mat-header-cell *matHeaderCellDef>
                      AS400 Cost Centre ID
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getCostCentreEmailAddresses(row)"
                    >
                      {{ row.AS400COSTCENTREID }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="COSTCENTRENAME">
                    <th mat-header-cell *matHeaderCellDef>Cost Centre Name</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getCostCentreEmailAddresses(row)"
                    >
                      {{ row.COSTCENTRENAME }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ACCOUNTID">
                    <th mat-header-cell *matHeaderCellDef>Account ID</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getCostCentreEmailAddresses(row)"
                    >
                      {{ row.ACCOUNTID }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ACCOUNTNAME">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getCostCentreEmailAddresses(row)"
                    >
                      {{ row.ACCOUNTNAME }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="AS400ACCOUNTID">
                    <th mat-header-cell *matHeaderCellDef>AS400 Account ID</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getCostCentreEmailAddresses(row)"
                    >
                      {{ row.AS400ACCOUNTID }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="CUSTOMERID">
                    <th mat-header-cell *matHeaderCellDef>Customer ID</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getCostCentreEmailAddresses(row)"
                    >
                      {{ row.CUSTOMERID }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="CUSTOMERNAME">
                    <th mat-header-cell *matHeaderCellDef>Customer Name</th>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      (click)="getCostCentreEmailAddresses(row)"
                    >
                      {{ row.CUSTOMERNAME }}
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="
                      displayedColumns_Cost_Center;
                      sticky: true
                    "
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns_Cost_Center"
                  ></tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="card007">
              <div class="mytable">
                <table
                  mat-table
                  [dataSource]="dataSource_Cost_Centre_Email_Addresses"
                  class="mat-elevation-z8"
                >
                  <ng-container matColumnDef="EMAIL_ADDRESS">
                    <th mat-header-cell *matHeaderCellDef>Email Address</th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.EMAILADDRESS }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="EMAIL_TYPE_DESCRIPTION">
                    <th mat-header-cell *matHeaderCellDef>
                      Email Type Description
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.MAILTYPEDESCRIPTION }}
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="
                      displayedColumns_Email_Addresses;
                      sticky: true
                    "
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="
                      let row;
                      columns: displayedColumns_Email_Addresses
                    "
                  ></tr>
                </table>
              </div>
            </div>
            <div class="actionsbtns">
              <div class="myrow">
                <button
                  mat-stroked-button
                  class="active"
                  (click)="insertEmail(setup_selected_value)"
                >
                  <p>Insert</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>Change</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>Delete</p>
                </button>
              </div>
              <div class="myrow">
                <button mat-stroked-button class="active">
                  <p>Change Email Address</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>Duplicate Email For All Types</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>Delete All Per Acc</p>
                </button>
              </div>
              <div class="myrow">
                <button mat-stroked-button class="active">
                  <mat-checkbox [(ngModel)]="ccDoAll">
                    <p>Do for all CC</p></mat-checkbox
                  >
                </button>
                <button mat-stroked-button class="active">
                  <p>From Email ID : 0</p>
                </button>
                <button mat-stroked-button class="active">
                  <p>To Email ID : 0</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
